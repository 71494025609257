import {
    CreateMqttCommand,
    MqttCommand,
} from 'src/app/shared/models/mqtt-command';
import { ApiService } from './api.service';
import { CachedApi, RawAccessAdapter, createRawAccess } from './cached-api';
import { TopLevelQueryParams, TopLevelRouteApi } from './top-level-route-api';

export interface MqttCommandsQueryParams extends TopLevelQueryParams {
    modelId?: number;
}

class RawMqttCommandsApi extends TopLevelRouteApi<
    MqttCommand,
    CreateMqttCommand,
    MqttCommandsQueryParams
> {
    get path() {
        return 'mqtt-commands';
    }

    constructor(api: ApiService) {
        super(api);
    }
}

export class MqttCommandsApi extends CachedApi<
    MqttCommand,
    CreateMqttCommand,
    void,
    MqttCommandsQueryParams
> {
    private raw: RawMqttCommandsApi;
    rawAccess: RawAccessAdapter<MqttCommand, CreateMqttCommand>;

    constructor(api: ApiService) {
        super();
        this.raw = new RawMqttCommandsApi(api);
        this.rawAccess = createRawAccess(this.raw, {});
    }
}
