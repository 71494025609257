import { ChartType, ChartThreshold } from 'src/app/chart/chart.model';
import { TopLevelQueryParams } from 'src/app/services/api/top-level-route-api';

export const samplingRateOptions = [0.1, 0.2, 0.5, 1, 2, 3, 6, 12, 20, 30, 60];

export interface CreateReport {
    name: string;
    startDate: Date;
    endDate?: Date;
    samplingRate?: number;
    deviceIds: number[];
    charts?: ChartMetaData[]; // serialized charts
}

export type Report = CreateReport & {
    id: number;
    customerId: number;
    modifyDate: Date;
    lastViewed: Date;
};

export interface ChartMetaData {
    title: string;
    type: ChartType;
    signals?: object[];
    items?: object[];
    model?: any;
    filter: any[];
    chartThresholds: ChartThreshold[];
}

export interface ReportsQueryParams extends TopLevelQueryParams {
    id?: number;
}

/**
 * Create a Report from a partial Report, for unit tests.
 */
export function testingReport(partial: Partial<Report>): Report {
    return {
        id: 0,
        customerId: 0,
        name: 'Fake Report',
        modifyDate: new Date(),
        startDate: new Date(),
        endDate: new Date(),
        deviceIds: [],
        charts: [],
        lastViewed: new Date(),
        ...partial,
    };
}
