import { log } from 'src/app/shared/log';
import { regexpDatasourceValue } from '../common.model';
import { Widget, WidgetType } from '../widget';
import {
    PrimitiveType,
    WidgetSettingDefinition,
    WidgetSettingType,
} from '../widget-settings';
import { execCode } from '../widget.model';

export class CustomWidget extends Widget {
    type = WidgetType.CUSTOM_WIDGET;

    topic: {
        datasourceName: string;
        topic: string;
        widgetValueType: string;
    }[] = []; // /mon/bus_id/can_id,

    customWidgetId: number;

    savedSettingValues;
    calculatedSettings: WidgetSettingDefinition[] = [];
    isSetup = true;
    styles = '';

    calcProps = {};

    constructor(data, customWidgetId) {
        super();
        this.savedSettingValues = data ?? {};
        if (customWidgetId && !isNaN(+customWidgetId)) {
            this.customWidgetId = +customWidgetId;
        } else {
            this.customWidgetId = customWidgetId;
        }
    }

    setSettings(
        settingDefinitions: WidgetSettingDefinition[],
        oldSettingDefinitions?: CustomSettingDefinition[]
    ) {
        oldSettingDefinitions ??= [];
        const ids = settingDefinitions.map((def) => def.id);
        const convertedSettings = convertOldSettingDefinitions(
            oldSettingDefinitions
            // Filter out the settings that have already been migrated.
        ).filter((def) => !ids.includes(def.id));
        this.settingDefinitions = settingDefinitions.concat(convertedSettings);
        this.calculatedSettings = this.settingDefinitions.filter(
            (s) => s.type == WidgetSettingType.Calculated
        );
        // Load saved setting values.
        const settingValues = convertOldSettingValues(
            oldSettingDefinitions,
            this.savedSettingValues
        );
        for (const id of ids) {
            if (id in this.savedSettingValues) {
                settingValues[id] = this.savedSettingValues[id];
            }
        }
        this.setDefaultSettingValues(settingValues);
        this.setWidgetValueData();
    }

    setStyles(styles: string) {
        // Coerce undefined to null.
        this.styles = styles ?? null;
    }

    parseSource(settingId: string): Array<any> {
        const setting = this.getSettingDefinition(settingId);
        const validTypes = [
            WidgetSettingType.Calculated,
            WidgetSettingType.Repeated,
        ];
        if (setting == undefined || !validTypes.includes(setting.type)) {
            throw Error(`Can not parse the setting with ID: ${settingId}`);
        }

        const settingValue = this.settings[settingId];
        if (!settingValue) {
            return [{ datasourceName: '', topic: '', widgetValueType: '' }];
        }

        let values = [];
        if (setting.type == WidgetSettingType.Repeated) {
            if (Array.isArray(settingValue)) {
                for (const item of settingValue) {
                    values.push(...Object.values(item));
                }
            } else {
                // handle values from old CALCULATED_MULTIPLE settings
                values.push(...Object.values(settingValue));
            }
        } else if (setting.type == WidgetSettingType.Calculated) {
            values = [settingValue];
        }

        const output = [];
        for (const value of values) {
            const match = value.match(regexpDatasourceValue);
            if (match) {
                output.push({
                    datasourceName: match[0],
                    topic: match[1],
                    widgetValueType: match[2],
                });
            }
        }
        return output;
    }

    setWidgetValueData() {
        this.topic = [];
        this.calculatedSettings.forEach((s, index) => {
            const value = this.settings[s.id];

            if (!value) {
                this.topic[index] = {
                    datasourceName: '',
                    topic: '',
                    widgetValueType: '',
                };
                return;
            }

            const match = value.match(regexpDatasourceValue);

            if (!match) {
                return;
            }

            this.topic[index] = {
                datasourceName: match[0],
                topic: match[1],
                widgetValueType: match[2],
            };
        });
    }

    override parseTopic(data) {
        const { busNames, canNames } = data;

        this.calculatedSettings.forEach((s, index) => {
            this.calcProps[s.id] = super.parseTopic_({
                busNames,
                canNames,
                topicStr: this.settings[s.id],
                topicObj: this.topic[index],
            });

            this.settings[s.id] = this.calcProps[s.id].code;
        });
    }

    runExecuteClientCode(data) {
        return execCode(this.settings[data.propName], data.datasources);
    }
}

export enum CustomSettingType {
    CALCULATED = 'calculated',
    CALCULATED_MULTIPLE = 'calculated_multiple',
    DATASOURCE = 'datasource',
    DROPDOWN_SELECTOR = 'dropdown_selector',
    TEXT = 'text',
}

export interface CustomSettingDefinition {
    name: string;
    display_name: string;
    description: string;
    options?: [];
    type: CustomSettingType;
}

export interface ICustomWidgetBody {
    render: () => void;
    onSettingsChanged: (settings) => void;
    onCalculatedValueChanged?: (settingName: string, newValue) => void;
}
function convertOldSettingDefinitions(
    settings: CustomSettingDefinition[]
): WidgetSettingDefinition[] {
    const newSettings: WidgetSettingDefinition[] = [];
    for (const setting of settings) {
        let type = null;
        let previewValue: PrimitiveType = '';
        const otherOptions: Partial<WidgetSettingDefinition> = {};
        switch (setting.type) {
            case CustomSettingType.CALCULATED:
                type = WidgetSettingType.Calculated;
                break;
            case CustomSettingType.CALCULATED_MULTIPLE:
                type = WidgetSettingType.Repeated;
                otherOptions.config = {
                    children: [
                        {
                            id: 'value',
                            type: WidgetSettingType.Calculated,
                            name: 'widget.settings.value',
                            previewValue: '',
                        },
                    ],
                };
                break;
            case CustomSettingType.DATASOURCE:
                type = WidgetSettingType.Datasource;
                break;
            case CustomSettingType.DROPDOWN_SELECTOR:
                type = WidgetSettingType.Select;
                previewValue = 0;
                otherOptions.defaultValue = 0;
                otherOptions.config = {
                    options: setting.options.map((name, value) => ({
                        name,
                        value,
                    })),
                };
                break;
            case CustomSettingType.TEXT:
                type = WidgetSettingType.Text;
                break;
            default:
                log.error('UNKNOWN SETTING TYPE:', setting.type);
                continue;
        }
        newSettings.push({
            ...otherOptions,
            id: setting.name,
            type,
            name: setting.display_name,
            previewValue,
        });
    }
    return newSettings;
}

/**
 * This is needed because CALCULATED_MULTIPLE had a strange value format that
 * needs to be converted.
 */
function convertOldSettingValues(settings: CustomSettingDefinition[], values) {
    const out = {};
    for (const setting of settings) {
        const id = setting.name;
        if (id in values) {
            if (
                setting.type == CustomSettingType.CALCULATED_MULTIPLE &&
                !Array.isArray(values[id])
            ) {
                // Convert from the old object format.
                out[id] = Object.values(values[id]).map((value) => ({ value }));
            } else {
                out[id] = values[id];
            }
        }
    }
    return out;
}
