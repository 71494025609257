<div
    class="flex grow items-center rounded-md border border-gray-400 bg-transparent text-base focus-within:!border-primary-500 dark:border-gray-500 dark:focus-within:!border-primary-400"
>
    <div *ngIf="icon" class="aspect-square h-6 pl-1 opacity-70">
        <mat-icon [svgIcon]="icon" [inline]="true" />
    </div>
    <input
        class="box-content min-w-0 grow bg-transparent outline-none"
        [ngClass]="small ? 'h-4 p-1 leading-none' : 'h-6 px-1.5 py-2'"
        #data="ngModel"
        [(ngModel)]="value"
        [disabled]="disabled"
        [min]="minimum"
        [pattern]="pattern"
        [type]="type"
    />
    <div
        *ngIf="suffix !== null"
        class="font-bold"
        [ngClass]="small ? 'p-1 leading-none' : 'px-1.5 py-2'"
    >
        {{ suffix | translate }}
    </div>
    <div
        *ngIf="(data.invalid || showError) && (data.touched || data.dirty)"
        class="aspect-square h-6 pr-1 text-red-500"
    >
        <mat-icon
            svgIcon="exclamation-thick"
            [matTooltip]="errorMessage | translate"
            [inline]="true"
        />
    </div>
</div>
