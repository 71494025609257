export const DATASOURCES_REGEX = /datasources((?:\[".+"\])*)/;
export const SEGMENT_REGEX = /\["([^"]*)"\]/;

export type ParsedPath = {
    datasourceName: string;
    topicPath: string;
};

/** Generate a topic path from the segments. */
export function generatePathFromSegments(topicSegments: string[]) {
    return topicSegments.map((s) => `["${s}"]`).join('');
}

export function parsePath(path: string): ParsedPath {
    const value = (path ? path : 'datasources').trim();
    const matches = value.match(DATASOURCES_REGEX);
    const isSimpleFormat = matches && matches[0] == value;
    if (isSimpleFormat) {
        const segmentsString = matches[1];
        if (segmentsString.length > 0) {
            const matches = segmentsString.match(SEGMENT_REGEX);
            return {
                datasourceName: matches[1],
                topicPath: segmentsString.replace(matches[0], ''),
            };
        } else {
            return { datasourceName: '', topicPath: '' };
        }
    }
    return null;
}

export function parseSegments(topicPath: string): string[] {
    const topicSegments = [];
    let hadMatch = true;
    do {
        const matches = topicPath.match(SEGMENT_REGEX);
        if (matches && matches.length >= 2) {
            hadMatch = true;
            topicSegments.push(matches[1]);
            topicPath = topicPath.replace(matches[0], '');
        } else {
            hadMatch = false;
        }
    } while (hadMatch);
    return topicSegments;
}

// Check if the value matches the basic datasources path format.
export function isPath(value: string) {
    return (value.match(DATASOURCES_REGEX) ?? [])[0] == value;
}
