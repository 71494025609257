import {
    CreateOrganization,
    Organization,
    OrganizationsQueryParams,
} from 'src/app/shared/models/customer';
import { OrganizationPreview } from 'src/app/shared/models/preview';
import { ApiService } from './api.service';
import {
    CachedApi,
    RawAccessAdapter,
    createRawAccess,
    ensureLoaded,
    waitForItem,
} from './cached-api';
import { TopLevelRouteApi } from './top-level-route-api';
import { environment } from 'src/environments/environment';
import { getCustomerRoleName } from '../constants';
import { TranslateService } from '@ngx-translate/core';
import { inject } from '@angular/core';

class RawOrganizationsApi extends TopLevelRouteApi<
    Organization,
    CreateOrganization,
    OrganizationsQueryParams
> {
    get path() {
        return 'customers';
    }

    constructor(api: ApiService) {
        super(api);
    }
}

export class OrganizationsApi extends CachedApi<
    Organization,
    CreateOrganization,
    OrganizationPreview,
    OrganizationsQueryParams
> {
    private raw: RawOrganizationsApi;
    rawAccess: RawAccessAdapter<Organization, CreateOrganization>;
    translate: TranslateService;

    constructor(private api: ApiService) {
        super();
        this.raw = new RawOrganizationsApi(api);
        this.rawAccess = createRawAccess(this.raw, {});
        this.translate = inject(TranslateService);
    }

    async loadPreview(id: number): Promise<OrganizationPreview> {
        await ensureLoaded([this.api.organizations, this.api.pins]);
        await waitForItem(this, id);
        const organization = this.get(id);
        const roleTranslationTerm = getCustomerRoleName(organization.role);
        const subtitle =
            roleTranslationTerm.length > 0
                ? this.translate.instant(roleTranslationTerm)
                : '';
        const imageUrl = organization.logo
            ? environment.apiUrl +
              '/api/v2/customers/' +
              organization.id +
              '/thumbnail'
            : environment.apiUrl + '/images/SZ-thumbnail.png';
        const isPinned = this.api.pins.has('organization', organization.id);
        return {
            type: 'organization',
            id,
            title: organization.name,
            subtitle,
            imageUrl,
            organization,
            isPinned,
        };
    }
}
