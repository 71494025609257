import { BasicRouteApi } from './basic-route-api';

export interface TopLevelQueryParams {
    searchTerm?: string;
    searchFields?: string;
    offset?: number;
    limit?: number;
    sort?: string;
    sortOrder?: 'ASC' | 'DESC';
}

export interface CreateResult {
    id: number;
    success: boolean;
}

export interface UpdateResult {
    success: boolean;
    message: string;
}

export interface DeleteResult {
    success: boolean;
    message: string;
    deleteCount: number;
}

export class TopLevelRouteApi<
    GetType,
    CreateType,
    QueryParamsType extends TopLevelQueryParams
> extends BasicRouteApi {
    transformBackendData(data): GetType {
        return data;
    }

    transformFrontendData(data: Partial<CreateType>) {
        return data;
    }

    async query(params?: QueryParamsType) {
        return (
            (await this.api.client
                .get(this.basePath, { params: this.buildParams(params as any) })
                .toPromise()) as any[]
        ).map((v) => this.transformBackendData(v)) as GetType[];
    }

    async get(id: number) {
        return this.transformBackendData(
            (await this.api.client
                .get(this.basePath + '/' + id)
                .toPromise()) as any
        ) as GetType;
    }

    async create(data: CreateType) {
        return (await this.api.client
            .post(this.basePath, this.transformFrontendData(data))
            .toPromise()) as CreateResult;
    }

    async update(id: number, data: Partial<CreateType>) {
        return (await this.api.client
            .patch(this.basePath + '/' + id, this.transformFrontendData(data))
            .toPromise()) as UpdateResult;
    }

    async delete(ids: number[]) {
        return (await this.api.client
            .delete(this.basePath, {
                params: this.buildParams({ ids: ids.join(',') }),
            })
            .toPromise()) as DeleteResult;
    }
}
