import {
    Component,
    DestroyRef,
    EventEmitter,
    OnInit,
    Output,
    inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { log } from '../../../../shared/log';
import { IWidgetDialog, WidgetDialogData } from '../../../models/common.model';

@Component({
    templateUrl: './pointer-widget-dialog.component.html',
    styleUrls: [
        './pointer-widget-dialog.component.scss',
        '../common/widget-setup-dialogs.scss',
    ],
})
export class PointerWidgetDialog implements IWidgetDialog, OnInit {
    @Output() settingsChanged = new EventEmitter();
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output() init = new EventEmitter();

    form: FormGroup;
    datasourceObj;
    value;
    destroyRef = inject(DestroyRef);

    constructor(private formBuilder: FormBuilder) {}

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            direction: new FormControl(''),
            value_text: new FormControl(''),
            units: new FormControl(''),
            animation: new FormControl(''),
            compass: new FormControl(''),
            colorPlate: new FormControl(''),
            colorText: new FormControl(''),
        });

        this.form.valueChanges
            .pipe(debounceTime(300), takeUntilDestroyed(this.destroyRef))
            .subscribe((data) => {
                log.info('this.form.valueChanges = ', data);
                this.settingsChanged.emit(data);
            });

        this.init.emit();
    }

    handleValueInputChange(value) {
        log.info('handleValueInputChange = ', value);
        this.value = value;
    }

    setData(data: WidgetDialogData) {
        const { settings, datasourceObj } = data;
        this.datasourceObj = datasourceObj;
        this.form.patchValue(settings, { emitEvent: false });
    }
}
