import { Widget, WidgetType } from '../widget';
import { WidgetSettingType } from '../widget-settings';

export class IndicatorWidget extends Widget {
    type = WidgetType.INDICATOR;

    constructor(settings?) {
        super(
            [
                {
                    id: 'title',
                    type: WidgetSettingType.Text,
                    name: 'widget.settings.title',
                    previewValue: 'Title',
                },
                {
                    id: 'value',
                    type: WidgetSettingType.Calculated,
                    name: 'widget.settings.value',
                    previewValue: 'true',
                    defaultValue: '',
                },
                {
                    id: 'onText',
                    type: WidgetSettingType.Text,
                    name: 'widget.settings.on_text',
                    previewValue: 'On',
                },
                {
                    id: 'offText',
                    type: WidgetSettingType.Text,
                    name: 'widget.settings.off_text',
                    previewValue: 'Off',
                },
            ],
            settings
        );
    }
}
