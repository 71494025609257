import { NgModule } from '@angular/core';
import { SearchDevicesPipe } from '../dialogs/select-devices-dialog/search-devices.pipe';
import { SecurePipe } from '../services/securePipe';
import { FileNamePipe } from './pipes/file-name-pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { OrderByTranslationPipe } from './pipes/order-by-translation.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { SearchPipe } from './pipes/search.pipe';

@NgModule({
    imports: [],
    exports: [
        SafePipe,
        SecurePipe,
        FileNamePipe,
        FilterPipe,
        SearchDevicesPipe,
        SearchPipe,
        OrderByTranslationPipe,
    ],
    declarations: [
        SafePipe,
        SecurePipe,
        FileNamePipe,
        FilterPipe,
        SearchDevicesPipe,
        SearchPipe,
        OrderByTranslationPipe,
    ],
})
export class SharedModule {}
