import {
    HistoricalDataByDataFileParams,
    HistoricalDataByDeviceParams,
    SignalData,
} from 'src/app/shared/models/historical-data';
import { ApiService } from './api.service';
import { BasicRouteApi } from './basic-route-api';

function transformParams(
    params: HistoricalDataByDeviceParams | HistoricalDataByDataFileParams
) {
    return {
        ...params,
        signalIds: params.signalIds.join(','),
    };
}

export class HistoricalDataApi extends BasicRouteApi {
    get path() {
        return 'historical-data';
    }

    constructor(api: ApiService) {
        super(api);
    }

    getByDevice(id: number, params: HistoricalDataByDeviceParams) {
        return this.retrieve<SignalData[]>(
            'by-device/' + id + '/data',
            transformParams(params)
        );
    }

    getByDataFile(id: number, params: HistoricalDataByDataFileParams) {
        return this.retrieve<SignalData[]>(
            'by-data-file/' + id + '/data',
            transformParams(params)
        );
    }
}
