export const environment = {
    production: false,
    apiUrl: 'https://spoke2.mrs-electronic.dev',
    mqttUrl: 'spoke3.mrs-electronic.dev',
    mqttPort: 443,
    mode: 'default',
    language: 'en',
    useBetaHistory: true,
    isSpokeZone: true,
    isLenticul: false,
};