export enum ExpressionType {
    Null,
    Boolean,
    Number,
    String,
    Scalar,
    List,
    Any
}

const scalarTypes = [
    ExpressionType.Boolean,
    ExpressionType.Number,
    ExpressionType.String,
];

/**
 * Check if the type matches the expected type.
 */
export function isType(type: ExpressionType, expected: ExpressionType): boolean {
    if (type == expected) {
        return true;
    }
    // Null shouldn't match anything else
    if (type == ExpressionType.Null) {
        return false;
    }
    // Special scalar types.
    if (expected == ExpressionType.Scalar) {
        return scalarTypes.includes(type);
    }
    // Any should match everything else
    if (expected == ExpressionType.Any) {
        return true;
    }
    return false;
}

/**
 * Get the type name.
 */
export function typeName(type: ExpressionType): string {
    switch (type) {
        case ExpressionType.Null:
            return 'expressions.type.null';
        case ExpressionType.Boolean:
            return 'expressions.type.boolean';
        case ExpressionType.Number:
            return 'expressions.type.number';
        case ExpressionType.String:
            return 'expressions.type.string';
        case ExpressionType.Scalar:
            return 'expressions.type.scalar';
        case ExpressionType.List:
            return 'expressions.type.list';
        case ExpressionType.Any:
            return 'expressions.type.any';
    }
}
