import { inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserPreview } from 'src/app/shared/models/preview';
import { CreateUser, User } from 'src/app/shared/models/user';
import { UserRole } from '../constants';
import { ApiService } from './api.service';
import {
    CachedApi,
    RawAccessAdapter,
    createRawAccess,
    waitForItem,
} from './cached-api';
import { TopLevelQueryParams, TopLevelRouteApi } from './top-level-route-api';

type ResetResult = {
    status: number;
    message: string;
};

export interface UsersQueryParams extends TopLevelQueryParams {
    'userRole[lt]'?: UserRole;
    customerId?: number;
}

export class RawUsersApi extends TopLevelRouteApi<
    User,
    CreateUser,
    UsersQueryParams
> {
    get path() {
        return 'users';
    }

    constructor(api: ApiService) {
        super(api);
    }

    async resetPassword(email: string) {
        return (await this.send('credentials/reset', { email })) as ResetResult;
    }

    updatePassword(data: { password: string; access_token: string }) {
        return this.patch('credentials/update', data);
    }
}

export class UsersApi extends CachedApi<
    User,
    CreateUser,
    UserPreview,
    UsersQueryParams
> {
    private raw: RawUsersApi;
    rawAccess: RawAccessAdapter<User, CreateUser>;
    translate: TranslateService;

    constructor(private api: ApiService) {
        super();
        this.raw = new RawUsersApi(api);
        this.rawAccess = createRawAccess(this.raw, {});
        this.translate = inject(TranslateService);
    }

    async loadPreview(id: number): Promise<UserPreview> {
        await waitForItem(this, id);
        const user = this.get(id);
        const organization = this.api.organizations.get(user.customerId);
        return {
            type: 'user',
            id,
            title: `${user.firstName} ${user.lastName}`,
            subtitle: organization?.name ?? '',
            details: [
                {
                    name: 'login.email',
                    value: user.email,
                },
                {
                    name: 'status',
                    value: this.translate.instant(
                        user.isLocked ? 'user.locked' : 'status.active',
                    ),
                },
                {
                    name: 'common.phone',
                    value: user.phone,
                },
            ],
            user,
        };
    }

    async resetPassword(email: string) {
        return this.raw.resetPassword(email) as Promise<ResetResult>;
    }

    async updatePassword(data: { password: string; access_token: string }) {
        return this.raw.updatePassword(data);
    }
}
