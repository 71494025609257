import {
    Component,
    DestroyRef,
    EventEmitter,
    OnInit,
    Output,
    inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { IWidgetDialog, WidgetDialogData } from '../../../models/common.model';

@Component({
    templateUrl: './monitor-grid-widget-dialog.component.html',
    styleUrls: [
        './monitor-grid-widget-dialog.component.scss',
        '../common/widget-setup-dialogs.scss',
    ],
})
export class MonitorGridWidgetDialog implements IWidgetDialog, OnInit {
    @Output() settingsChanged = new EventEmitter();
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output() init = new EventEmitter();

    form: FormGroup;
    datasourceObj;
    destroyRef = inject(DestroyRef);

    constructor(private formBuilder: FormBuilder) {}

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            mg_bg1: new FormControl(''),
            mg_bg2: new FormControl(''),
            mg_bg3: new FormControl(''),
            mg_bg4: new FormControl(''),
            mg_bg5: new FormControl(''),
            mg_bg6: new FormControl(''),
            mg_data1: new FormControl(''),
            mg_data2: new FormControl(''),
            mg_data3: new FormControl(''),
            mg_data4: new FormControl(''),
            mg_data5: new FormControl(''),
            mg_data6: new FormControl(''),
            mg_title1: new FormControl(''),
            mg_title2: new FormControl(''),
            mg_title3: new FormControl(''),
            mg_title4: new FormControl(''),
            mg_title5: new FormControl(''),
            mg_title6: new FormControl(''),
            mg_unit1: new FormControl(''),
            mg_unit2: new FormControl(''),
            mg_unit3: new FormControl(''),
            mg_unit4: new FormControl(''),
            mg_unit5: new FormControl(''),
            mg_unit6: new FormControl(''),
        });

        this.form.valueChanges
            .pipe(debounceTime(300), takeUntilDestroyed(this.destroyRef))
            .subscribe((data) => {
                this.settingsChanged.emit(data);
            });

        this.init.emit();
    }

    setData(data: WidgetDialogData) {
        const { settings, datasourceObj } = data;
        this.datasourceObj = datasourceObj;
        this.form.patchValue(settings, { emitEvent: false });
    }
}
