import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import moment from 'moment';

@Injectable({ providedIn: 'root' })
export class DateTimeService {
    // These are set by SettingsService.update
    timeFormat = '';
    dateFormat = '';
    isUtcTime = false;
    defaultFormat = 'YYYY-MM-DD HH:mm:ss';

    get dateTimeFormat(): string {
        return this.dateFormat + ' ' + this.timeFormat;
    }

    convertLocalToUTC(date) {
        return new Date(
            date.getUTCFullYear(),
            date.getUTCMonth(),
            date.getUTCDate(),
            date.getUTCHours(),
            date.getUTCMinutes(),
            date.getUTCSeconds(),
            date.getUTCMilliseconds(),
        );
    }

    /**
     * Get date and time in string
     */
    dateTimeString(dateTime: Date = new Date()): string {
        return this.isUtcTime
            ? formatDate(dateTime, this.dateTimeFormat, 'en', '+0000') +
                  ' (UTC)'
            : formatDate(dateTime, this.dateTimeFormat, 'en');
    }

    /**
     * Get time in string
     */
    timeString(dateTime: Date = new Date()): string {
        return this.isUtcTime
            ? formatDate(dateTime, this.timeFormat, 'en', '+0000') + ' (UTC)'
            : formatDate(dateTime, this.timeFormat, 'en');
    }

    /**
     * Get date in string
     */
    dateString(dateTime: Date = new Date()): string {
        return this.isUtcTime
            ? formatDate(dateTime, this.dateFormat, 'en', '+0000')
            : formatDate(dateTime, this.dateFormat, 'en');
    }

    /**
     * This method translates date into a specific format
     * @param date Date to be modified
     * @returns date in specific format
     */
    getDatetimeStr(date: Date | string): string {
        return date instanceof Date
            ? moment(date).format(this.defaultFormat)
            : moment(date).utc().format(this.defaultFormat);
    }
}
