<div class="flex flex-row justify-center gap-3 pt-3">
    <button
        mat-stroked-button
        [disabled]="secondaryDisabled"
        (click)="secondaryClick.emit()"
    >
        {{ secondaryText | translate }}
    </button>

    <button
        mat-stroked-button
        [class.!hidden]="tertiaryHidden"
        (click)="tertiaryClick.emit()"
    >
        {{ tertiaryText | translate }}
    </button>

    <button
        mat-flat-button
        [color]="primaryColor"
        [disabled]="primaryDisabled"
        (click)="primaryClick.emit()"
    >
        {{ primaryText | translate }}
    </button>
</div>
