import {
    Component,
    EventEmitter,
    forwardRef,
    HostBinding,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import {
    ControlValueAccessor,
    FormControl,
    NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { isDefined } from 'src/app/shared/common';

@Component({
    selector: 'sz-input',
    templateUrl: './input.component.html',
    styleUrls: ['./input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputComponent),
            multi: true,
        },
    ],
})
export class InputComponent implements ControlValueAccessor {
    private _value: string | number = '';
    private _errorMessage = '';

    @Input() disabled = false;
    @Input() icon: string;
    @Input() suffix: string | null = null;
    @Input() minimum?: number;
    @Input() pattern?: string;
    @Input() small = false;
    // Allow the parent component to implement extra validation.
    @Input() showError = false;
    @Input() set errorMessage(message: string) {
        this._errorMessage = message;
    }
    get errorMessage(): string {
        if (
            isDefined(this.minimum) &&
            typeof this.value === 'number' &&
            this.value < this.minimum
        ) {
            return this.translate.instant('common.error.minimum_value', {
                min: this.minimum,
            });
        }
        return this._errorMessage;
    }
    // TODO: Implement in #920
    @Input() errorMessages?: Record<string, string>;
    @Input() type = 'text';
    @Output() valueChange = new EventEmitter<string | number>();

    @HostBinding('style.opacity')
    get opacity() {
        return this.disabled ? 0.25 : 1;
    }
    @ViewChild('data') data: FormControl;

    // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
    onChange = (value: string | number) => {};
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onTouched = () => {};

    @Input() set value(v: string | number) {
        if (!this.disabled && (!this.data.invalid || !this.showError)) {
            this.writeValue(v);
        }
    }
    get value(): string | number {
        return this._value;
    }

    constructor(private translate: TranslateService) {}

    writeValue(rawValue): void {
        let value;
        if (isDefined(rawValue)) {
            value =
                this.type == 'number'
                    ? rawValue !== '' && rawValue !== null
                        ? +rawValue
                        : null
                    : rawValue;
        }
        this._value = value;
        this.onChange(this._value);
        if (rawValue != null) {
            this.valueChange.emit(this._value);
        }
    }

    registerOnChange(fn: (value: string) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
}
