<div class="flex items-center">
    <mat-select
        class="grow rounded-md border border-gray-400 bg-transparent px-1.5 py-2 text-base focus:!border-primary-500 dark:border-gray-600"
        [(ngModel)]="value"
        [disabled]="disabled"
        [multiple]="isMultiple"
    >
        <mat-option *ngFor="let option of options" [value]="option.value">
            <mat-icon *ngIf="option.icon" [svgIcon]="option.icon"> </mat-icon>
            {{ option.name | translate }}
        </mat-option>
    </mat-select>
    <mat-icon
        *ngIf="isRequired && !value"
        class="!h-8 !w-8 pl-1 text-red-500"
        matTooltip="{{ 'error.select_is_required' | translate }}"
        svgIcon="exclamation-thick"
    >
    </mat-icon>
</div>
