import { ChartMessage } from 'src/app/chart/chart.model';
import {
    DataFile,
    DataFileStats,
    DataFileStatsParams,
    DataFilesQueryParams,
    GpsDataPoint,
} from 'src/app/shared/models/data-file';
import { TopLevelFileRouteApi } from './top-level-file-route-api';
import { importDateString } from 'src/app/shared/common';
import { DataFilePreview } from 'src/app/shared/models/preview';
import { ApiService } from './api.service';
import {
    CachedApi,
    RawAccessAdapter,
    createRawAccess,
    ensureLoaded,
    waitForItem,
} from './cached-api';
import { filter } from 'rxjs/operators';
import { isDefined } from 'src/app/shared/common';
import { DateTimeService } from '../date-time.service';
import { inject } from '@angular/core';
import byteSize from 'byte-size';

export class RawDataFilesApi extends TopLevelFileRouteApi<
    DataFile,
    DataFile,
    DataFilesQueryParams
> {
    get path() {
        return 'data-files';
    }

    get fileSubPath() {
        return 'file';
    }

    constructor(api: ApiService) {
        super(api);
    }

    get(id: number) {
        return this.retrieve<DataFile>(`${id}`);
    }

    transformBackendData(data): DataFile {
        return {
            ...data,
            receivedOnDate: importDateString(data.receivedOnDate),
            startLog: importDateString(data.startLog),
            endLog: importDateString(data.endLog),
        };
    }

    getMessages(id: number) {
        return this.retrieve<ChartMessage[]>(`${id}/messages`);
    }

    async getGpsData(id: number): Promise<GpsDataPoint[]> {
        type RawGpsDataPoint = {
            timestamp: string;
            lat: number;
            lon: number;
        };
        return (await this.retrieve<RawGpsDataPoint[]>(`${id}/gps-data`)).map(
            (data) => ({
                timestamp: importDateString(data.timestamp),
                lat: data.lat,
                lon: data.lon,
            }),
        );
    }

    getStats(params: DataFileStatsParams) {
        return this.retrieve<DataFileStats[]>(`stats`, params);
    }
}

export class DataFilesApi extends CachedApi<
    DataFile,
    DataFile,
    DataFilePreview,
    DataFilesQueryParams
> {
    private fileTypeIcon = new Map([
        ['log', 'chart-line-variant'],
        ['debug', 'bug'],
        ['gps', 'earth'],
        ['event', 'alert-decagram'],
        ['dmesg', 'receipt-outline'],
        ['journal', 'script-text-outline'],
        ['txt', 'file-document'],
    ]);

    private raw: RawDataFilesApi;
    private dateTime: DateTimeService;
    rawAccess: RawAccessAdapter<DataFile, DataFile>;

    constructor(private api: ApiService) {
        super();
        this.raw = new RawDataFilesApi(api);
        this.rawAccess = createRawAccess(this.raw, {});
        this.dateTime = inject(DateTimeService);
    }

    attachTriggers(): void {
        this.api.pageViews.updated$
            .pipe(
                filter(
                    ({ feature, itemId }) =>
                        feature === 'data-files' && isDefined(itemId),
                ),
            )
            .subscribe(({ itemId }) => this.loadItem(itemId));
    }

    async loadPreview(id: number): Promise<DataFilePreview> {
        await ensureLoaded([
            this.api.dataFiles,
            this.api.devices,
            this.api.organizations,
        ]);
        await waitForItem(this, id);
        const dataFile = this.get(id);
        const device = this.api.devices.get(dataFile.deviceId);
        const subtitle = this.dateTime.dateTimeString(dataFile.receivedOnDate);
        const details = [
            {
                name: 'organization',
                value: this.api.organizations.get(device.customerId).name,
            },
            { name: 'type', value: dataFile.type },
            {
                name: 'size',
                value: byteSize(dataFile.byteSize, { precision: 2 }),
            },
        ];
        if (dataFile.startLog) {
            details.push({
                name: 'data_files.start',
                value: this.dateTime.dateTimeString(dataFile.startLog),
            });
        }
        if (dataFile.endLog) {
            details.push({
                name: 'data_files.end',
                value: this.dateTime.dateTimeString(dataFile.endLog),
            });
        }
        return {
            type: 'data-file',
            id,
            title: device.identifier,
            subtitle,
            icon: this.fileTypeIcon.get(dataFile.type),
            dataFile,
            details,
        };
    }

    getMessages(id: number) {
        return this.raw.retrieve<ChartMessage[]>(`${id}/messages`);
    }

    async getGpsData(id: number): Promise<GpsDataPoint[]> {
        type RawGpsDataPoint = {
            timestamp: string;
            lat: number;
            lon: number;
        };
        return (
            await this.raw.retrieve<RawGpsDataPoint[]>(`${id}/gps-data`)
        ).map((data) => ({
            timestamp: importDateString(data.timestamp),
            lat: data.lat,
            lon: data.lon,
        }));
    }

    getStats(params: DataFileStatsParams) {
        return this.raw.retrieve<DataFileStats[]>(`stats`, params);
    }

    upload(id: number, data: Blob) {
        return this.raw.upload(id, data);
    }

    download(id: number) {
        return this.raw.download(id);
    }

    query(params?: DataFilesQueryParams) {
        return this.raw.query(params);
    }
}
