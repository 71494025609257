import { DateTime } from 'luxon';
import { palette } from 'src/app/shared/palette';
import { CustomerRole, UserRole } from '../services/constants';
import { snakeCase } from 'change-case';

export function isMobileDevice(): boolean {
    let isMobile = false; //initiate as false
    // device detection
    if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
            navigator.userAgent,
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
            navigator.userAgent.substr(0, 4),
        )
    ) {
        isMobile = true;
    }

    return isMobile;
}

/**
 * Check if the current window width is a mobile screen size.
 */
export function isMobile(): boolean {
    return window.innerWidth < 640;
}

/**
 * Check if the current user's customer role includes the given role.
 */
export function hasRole(customerRole: CustomerRole) {
    return getCustomerRole() <= customerRole;
}

/**
 * Check if the current user's customer role is the given role.
 */
export function isRole(customerRole: CustomerRole) {
    return getCustomerRole() == customerRole;
}

/**
 * Check if the current user's permission is ReadWrite.
 */
export function canWrite() {
    return getUserPermissions() == UserRole.ReadWrite;
}

/**
 * Convert a kebab case string into a camel case string.
 */
export function kebabCaseToCamelCase(kebabString: string) {
    return kebabString.replace(/-./g, (x) => x[1].toUpperCase());
}

/**
 * Convert a snake case string into a camel case string.
 */
export function snakeCaseToCamelCase(snakeString: string) {
    return snakeString.replace(/_./g, (x) => x[1].toUpperCase());
}

/**
 * Perform a union set operation.
 * From https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Set#implementing_basic_set_operations.
 */
export function union(setA, setB) {
    const _union = new Set(setA);
    for (const elem of setB) {
        _union.add(elem);
    }
    return _union;
}

/**
 * Asynchronously delay the given number of milliseconds.
 */
export function delay(milliseconds: number) {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
}

/**
 * Use setTimeout to wait for the next micro task.
 */
export function pause() {
    return delay(0);
}

/**
 * Calculate what has changed.
 */
export function diffChanges(original, current) {
    return Object.fromEntries(
        Object.entries(current).filter(([id, value]) => original[id] != value),
    );
}

/**
 * Switch the key mapping to map the opposite direction.
 */
export function invertMapping(keyMapping) {
    return Object.fromEntries(
        Object.entries(keyMapping).map(([oldId, newId]) => [newId, oldId]),
    );
}

/**
 * Convert the keys of the object using the key mapping object.
 */
export function convertKeys(values, keyMapping) {
    return Object.fromEntries(
        Object.entries(keyMapping)
            .filter(([oldId]: [string, string]) => oldId in values)
            .map(([oldId, newId]) => [newId, values[oldId]]),
    );
}

/**
 * Get file name component of a file path.
 */
export function getFileName(value: string) {
    if (value && value.includes('/')) {
        const shorterName = value.split('/');
        return shorterName.pop();
    } else {
        return value;
    }
}

/**
 * Convert an array to an object, keyed by the given property.
 */
export function arrayToObject(array, property) {
    return Object.fromEntries(array.map((item) => [item[property], item]));
}

/**
 * Convert an old theme name if needed.
 */
export function convertColor(color) {
    const regex = /#([a-f0-9]{3}){1,2}\b/i;
    if (regex.test(color)) {
        return color;
    } else if (color in palette) {
        return palette[color];
    }
    return palette.Default;
}

/**
 * Check if the object is empty.
 */
export function isEmpty(obj) {
    return Object.keys(obj).length == 0;
}

/**
 * Merge objects.
 */
export function merge(...objs) {
    return Object.assign({}, ...objs);
}

/**
 * Merge recursively.
 */
export function mergeObjectsRecursively(
    objects: Record<string, unknown>[],
): Record<string, unknown> {
    const mergedObject: Record<string, unknown> = {};

    for (const obj of objects) {
        for (const key in obj) {
            if (Object.hasOwn(obj, key)) {
                if (typeof obj[key] === 'object' && obj[key] !== null) {
                    mergedObject[key] = mergeObjectsRecursively([
                        mergedObject[key] as Record<string, unknown>,
                        obj[key] as Record<string, unknown>,
                    ]);
                } else {
                    mergedObject[key] = obj[key];
                }
            }
        }
    }

    return mergedObject;
}

/**
 * Bound a number in a range.
 */
export function bound(value, min, max) {
    if (value < min) {
        return min;
    } else if (value > max) {
        return max;
    }
    return value;
}

/**
 * Deep clone a value.
 */
export function clone(input) {
    if (isDefined(structuredClone)) {
        // TODO: replace all `clone` and `cloneArray` with `structuredClone`.
        return structuredClone(input);
    }
    if (input === null || typeof input !== 'object') {
        return input;
    } else if (Array.isArray(input)) {
        return cloneArray(input);
    }
    const output = {};
    for (const key in input) {
        if (Object.hasOwn(input, key)) {
            output[key] = clone(input[key]);
        }
    }
    return output;
}

/**
 * Shallow clone an array.
 */
export function cloneArray(array) {
    return array.map((item) => clone(item));
}

type SegmentType = string | number;

/**
 * Get a value in a nested object.
 */
export function getNestedValue(object, path: SegmentType[]) {
    if (path.length == 0) {
        return object;
    }
    const firstSegment = path[0];
    if (path.length == 1) {
        return object[firstSegment];
    } else {
        if (!(firstSegment in object)) {
            return undefined;
        }
        return getNestedValue(object[firstSegment], path.slice(1));
    }
}

/**
 * Check if the nested object contains a value at the given path.
 */
export function hasPath(object, path: SegmentType[]) {
    return isDefined(getNestedValue(object, path));
}

/**
 * Set a value in a nested object, creating objects as needed.
 */
export function setNestedValue(object, path: SegmentType[], value) {
    const firstSegment = path[0];
    if (path.length == 1) {
        object[firstSegment] = value;
    } else {
        setDefault(object, firstSegment, {});
        setNestedValue(object[firstSegment], path.slice(1), value);
    }
}

/**
 * Update an object in a nested object, merging as needed.
 */
export function updateNestedObject(object, path: string[], value: object) {
    setNestedValue(object, path, merge(getNestedValue(object, path), value));
}

/**
 * Set the value of the key in the object, if the key isn't in the object.
 */
export function setDefault(object, key, value) {
    if (!(key in object)) {
        object[key] = value;
    }
}

/**
 * Check if the value is a number.
 */
export function isNumber(value: string | number): boolean {
    return !isNaN(+value);
}

/**
 * Zip multiple arrays together.
 */
export function zip(...arrays) {
    const length = arrays[0].length;
    for (const array of arrays) {
        if (array.length != length) {
            throw Error('Arrays to be zipped must all have the same length.');
        }
    }
    const zipped = [];
    for (let i = 0; i < length; i++) {
        const item = [];
        for (const array of arrays) {
            item.push(array[i]);
        }
        zipped.push(item);
    }
    return zipped;
}

/**
 * Convert the string to title case.
 */
export function titleCase(str) {
    return str.replace(
        /\w\S*/g,
        (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(),
    );
}

/**
 * Import a date from a UTC string to a local Date object
 */
export function importDateString(date: string): Date {
    if (date == null) {
        return null;
    }
    if (DateTime.fromISO(date).isValid) {
        return DateTime.fromISO(date).toLocal().toJSDate();
    }
}

/**
 * Export a date from a local Date object to a UTC string
 */
export function exportDateString(date: Date): string {
    if (DateTime.fromJSDate(date).isValid) {
        return DateTime.fromJSDate(date).toUTC().toISO();
    }
}

/**
 * Return a new array with only unique values.
 */
export function uniqueArray<T>(array: T[]): T[] {
    return Array.from(new Set(array));
}

/**
 * Split an array into smaller chunks.
 */
export function chunkArray(array: unknown[], chunkSize = 100) {
    const chunks = [];
    const operatingArray = cloneArray(array); // don't mutate the input array
    while (operatingArray.length > 0) {
        const chunk = operatingArray.splice(0, chunkSize);
        chunks.push(chunk);
    }
    return chunks;
}

/**
 * Swap two element in an array.
 */
export function swap(array, i, j) {
    const temp = array[i];
    array[i] = array[j];
    array[j] = temp;
}

/**
 * Check if the array values are equivalent.
 */
export function arrayEquals(a: unknown[], b: unknown[]) {
    if (a === b) {
        return true;
    }
    if (a.length != b.length) {
        return false;
    }
    return a.every((v, index) => isDeepEqual(v, b[index]));
}

/**
 * Check if the object values are equivalent.
 */
export function objectEquals(a: object, b: object) {
    if (
        !arrayEquals(
            Object.keys(a).filter((item) => isDefined(a[item])),
            Object.keys(b).filter((item) => isDefined(b[item])),
        )
    ) {
        return false;
    }
    for (const key in a) {
        if (!isDeepEqual(a[key], b[key])) {
            return false;
        }
    }
    return true;
}

/**
 * Check if the values are equivalent.
 * Recursively compare arrays and objects.
 */
export function isDeepEqual(a, b) {
    if (a == null || b == null) {
        return a == b;
    }
    if (Array.isArray(a) || Array.isArray(b)) {
        if (!Array.isArray(a) || !Array.isArray(b)) {
            return false;
        }
        return arrayEquals(a, b);
    }
    if (typeof a == 'object' && typeof b == 'object') {
        return objectEquals(a, b);
    }
    return a == b;
}

export function convertToArray<T>(enumObj: T): T[keyof T][] {
    return Object.values(enumObj);
}

/**
 * Get data from local or session storage.
 */
export function getFromStorage(key: string) {
    return localStorage.getItem(key) ?? sessionStorage.getItem(key);
}

/**
 * Returns the current user's customer role.
 */
export function getCustomerRole(): CustomerRole {
    return +getFromStorage('customerRole');
}

/**
 * Returns the current user's customer ID.
 */
export function getCustomerId(): number {
    return +getFromStorage('customerId');
}

/**
 * Returns the current user's email.
 */
export function getUserEmail(): string {
    return getFromStorage('user');
}

/**
 * Returns the current user's ID.
 */
export function getUserId(): number {
    return +getFromStorage('userId');
}

/**
 * Returns the current user's permissions.
 */
export function getUserPermissions(): number {
    return +getFromStorage('userRole');
}

/**
 * Returns the current user's access token.
 */
export function getAccessToken(): string {
    return getFromStorage('token');
}

/**
 * Get the current user's OEM ID.
 */
export function getOemId() {
    return +getFromStorage('oemCustomerId');
}

/**
 * Check if the value is non-null and has a length greater than 0.
 */
export function isNonEmpty(value?: unknown[]) {
    return !!value && value.length > 0;
}

/**
 * Update some fields of the object.
 */
export function update(obj, updates) {
    for (const key in updates) {
        obj[key] = updates[key];
    }
}

/**
 * Check if value is not undefined.
 */
export const isDefined = (value) => typeof value != 'undefined';

/**
 * Return a function that will filter an array by the given filters.
 */
export const filterBy =
    <GetType, ParamsType>(filters: Partial<ParamsType>) =>
    (item: GetType) =>
        !Object.keys(filters).some((field) => item[field] != filters[field]);

/**
 * Return a function that will filter an array by the given search term, using
 * the given fields.
 */
export const searchBy = <GetType>(fields: string[], term: string) => {
    term = term.toLowerCase();
    return term.length == 0
        ? // eslint-disable-next-line @typescript-eslint/no-unused-vars
          (item: GetType) => true
        : (item: GetType) =>
              fields.some((field) =>
                  (item[field] ?? '').toString().toLowerCase().includes(term),
              );
};

/**
 * Return a comparator that can be used to sort an array by the given field.
 */
export const sortBy = (field: string, descendingOrder: boolean) => (a, b) => {
    if (a[field] < b[field]) {
        return descendingOrder ? 1 : -1;
    } else if (a[field] > b[field]) {
        return descendingOrder ? -1 : 1;
    }
    return 0;
};

/**
 * Return a function that will by filter an array by applying the offset and
 * limit values.
 */
export const offsetAndLimitBy = (
    offset: number | null,
    limit: number | null,
) => {
    if (limit == null) {
        if (offset == null) {
            return () => true;
        }
        return (_, index) => index >= offset;
    }
    offset ??= 0;
    return (_, index) => index >= offset && index < offset + limit;
};

/**
 * Parse comma-separated list from a value given in a query parameter.
 * Return null if the value isn't specified.
 */
export const parseQueryArray = (value: string) => {
    if (isDefined(value)) {
        return value ? value.split(',').map((idString) => +idString) : [];
    }
    return null;
};

/**
 * Convert camelCase keys of an object to snake_case keys.
 */
export const camelToSnakeKeys = (object: object) =>
    Object.fromEntries(
        Object.entries(object).map(([key, value]) => [snakeCase(key), value]),
    );

/**
 * Check if the two arrays have any intersections.
 */
export const hasIntersection = (a1, a2) => a1.some((item) => a2.includes(item));

/**
 * Converts time from minutes to seconds.
 * @param {number} minutes - The time in minutes.
 * @returns {number} The equivalent time in seconds.
 */
export function minutesToSeconds(minutes: number): number {
    return minutes * 60;
}
