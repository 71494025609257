import { Widget, WidgetType } from '../widget';
import { WidgetSettingType } from '../widget-settings';

export class DonutWidget extends Widget {
    type = WidgetType.DonutWidget;

    constructor(settings?) {
        super(
            [
                {
                    id: 'title',
                    type: WidgetSettingType.Text,
                    name: 'widget.settings.title',
                    defaultValue: '',
                    previewValue: '',
                },
                {
                    id: 'datasets',
                    type: WidgetSettingType.Repeated,
                    name: 'widget.settings.datasets',
                    previewValue: [
                        { label: 'Four', value: '4' },
                        { label: 'Three', value: '3' },
                    ],
                    config: {
                        children: [
                            {
                                id: 'label',
                                type: WidgetSettingType.Text,
                                name: 'widget.settings.label',
                                defaultValue: 'Value',
                                previewValue: null,
                            },
                            {
                                id: 'value',
                                type: WidgetSettingType.Calculated,
                                name: 'widget.settings.value',
                                previewValue: null,
                            },
                        ],
                    },
                },
            ],
            transformOldSettings(settings)
        );
    }
}

function transformOldSettings(settings) {
    if (!settings) {
        return settings;
    }
    // The old datasets key was called "value".
    return { ...settings, datasets: settings['datasets'] ?? settings['value'] };
}
