import { HistoryItem } from 'src/app/shared/models/version';
import { ApiService } from './api.service';
import { BasicRouteApi } from './basic-route-api';

export class VersionApi extends BasicRouteApi {
    get path() {
        return 'versions';
    }

    constructor(api: ApiService) {
        super(api);
    }

    async history(): Promise<HistoryItem[]> {
        return await this.api.client
            .get<HistoryItem[]>(this.basePath + '/history')
            .toPromise();
    }
}
