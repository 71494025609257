<form [formGroup]="form" class="settings-form">
    <app-text-area-select
        [parent]="form"
        name="direction"
        (valueChange)="handleValueInputChange($event)"
    >
        Heading Indicator
    </app-text-area-select>

    <app-text-area-select
        [parent]="form"
        name="value_text"
        (valueChange)="handleValueInputChange($event)"
    >
        Heading Text
    </app-text-area-select>

    <mat-form-field>
        <input formControlName="units" matInput placeholder="units" />
    </mat-form-field>

    <mat-checkbox formControlName="animation" class="anim-check"
        >Animation</mat-checkbox
    >

    <mat-checkbox formControlName="compass" class="anim-check"
        >Compass</mat-checkbox
    >

    <div class="colors-container">
        <mat-form-field>
            <input
                type="color"
                formControlName="colorPlate"
                matInput
                placeholder="Plate Color"
            />
        </mat-form-field>

        <mat-form-field>
            <input
                type="color"
                formControlName="colorText"
                matInput
                placeholder="Text Color"
            />
        </mat-form-field>
    </div>
</form>
