import {
    CreateGeoFence,
    GeoFence,
    GeoFenceQueryParams,
} from 'src/app/shared/models/geo-fence';
import { GeoFencePreview } from 'src/app/shared/models/preview';
import { ApiService } from './api.service';
import {
    CachedApi,
    RawAccessAdapter,
    createRawAccess,
    ensureLoaded,
    waitForItem,
} from './cached-api';
import { TopLevelRouteApi } from './top-level-route-api';

export class RawGeoFencesApi extends TopLevelRouteApi<
    GeoFence,
    CreateGeoFence,
    GeoFenceQueryParams
> {
    get path() {
        return 'geo-fences';
    }

    constructor(api: ApiService) {
        super(api);
    }

    transformBackendData(data): GeoFence {
        return {
            ...data,
            actions: data.actions?.map((a) => ({
                ...a,
                emailAddresses: a.emailAddresses?.split(','),
                phoneNumbers: a.phoneNumbers?.split(','),
            })),
        };
    }

    transformFrontendData(data): GeoFence {
        return {
            ...data,
            actions: data.actions?.map((a) => ({
                ...a,
                emailAddresses: a.emailAddresses?.join(','),
                phoneNumbers: a.phoneNumbers?.join(','),
            })),
        };
    }
}

export class GeoFencesApi extends CachedApi<
    GeoFence,
    CreateGeoFence,
    GeoFencePreview,
    GeoFenceQueryParams
> {
    raw: RawGeoFencesApi;
    rawAccess: RawAccessAdapter<GeoFence, CreateGeoFence>;

    constructor(private api: ApiService) {
        super();
        this.raw = new RawGeoFencesApi(api);
        this.rawAccess = createRawAccess(this.raw, {});
    }

    async loadPreview(id: number): Promise<GeoFencePreview> {
        await ensureLoaded([
            this.api.organizations,
            this.api.devices,
            this.api.pins,
        ]);
        await waitForItem(this, id);
        const geoFence = this.get(id);
        const isPinned = this.api.pins.has('geo-fence', geoFence.id);
        const organization = this.api.organizations.get(geoFence.customerId);
        const devices = geoFence.deviceIds
            .map((id) => this.api.devices.get(id))
            .filter((d) => d != null);
        return {
            type: 'geo-fence',
            id,
            title: geoFence.name,
            subtitle: organization?.name ?? '',
            devices,
            isPinned,
            geoFence,
        };
    }
}
