import { Widget, WidgetType } from '../widget';
import { WidgetSettingType } from '../widget-settings';
import { execCode } from '../widget.model';

export enum TextDisplaySize {
    Regular = 'regular',
    Big = 'big',
}

export class TextWidget extends Widget {
    type = WidgetType.Text;

    constructor(settings?) {
        super(
            [
                {
                    id: 'title',
                    type: WidgetSettingType.Text,
                    name: 'title',
                    previewValue: 'Current',
                },
                {
                    id: 'size',
                    type: WidgetSettingType.Select,
                    name: 'Size',
                    defaultValue: TextDisplaySize.Regular,
                    previewValue: TextDisplaySize.Regular,
                    config: {
                        options: [
                            { value: TextDisplaySize.Regular, name: 'regular' },
                            { value: TextDisplaySize.Big, name: 'big' },
                        ],
                    },
                },
                {
                    id: 'value',
                    type: WidgetSettingType.Calculated,
                    name: 'widget.settings.value',
                    previewValue: '3.14',
                },
                {
                    id: 'units',
                    type: WidgetSettingType.Text,
                    name: 'widget.settings.unit',
                    previewValue: 'A',
                },
                {
                    id: 'animate',
                    type: WidgetSettingType.Checkbox,
                    name: 'Animate Value Changes',
                    defaultValue: true,
                    previewValue: true,
                },
            ],
            settings
        );
    }
}
