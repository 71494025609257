import { TranslateService } from '@ngx-translate/core';
import {
    importDateString,
    isDefined,
    uniqueArray,
} from 'src/app/shared/common';
import {
    CreateReport,
    Report,
    ReportsQueryParams,
    samplingRateOptions,
} from 'src/app/shared/models/report';
import { DateTimeService } from '../date-time.service';
import { ApiService } from './api.service';
import {
    CachedApi,
    RawAccessAdapter,
    createRawAccess,
    ensureLoaded,
    waitForItem,
} from './cached-api';
import { TopLevelRouteApi } from './top-level-route-api';
import { inject } from '@angular/core';
import { ReportPreview } from 'src/app/shared/models/preview';
import { getTypeIcon } from 'src/app/chart/chart.model';
import { filter } from 'rxjs/operators';

class RawReportsApi extends TopLevelRouteApi<
    Report,
    CreateReport,
    ReportsQueryParams
> {
    get path() {
        return 'reports';
    }

    constructor(api: ApiService) {
        super(api);
    }

    transformBackendData(data): Report {
        let samplingRate = data['samplingRate'] ?? null;
        if (samplingRate != null) {
            let index = 0;
            // Find a sampling rate option greater than or equal to the given
            // one. (We do this in case the options list has changed).
            while (index < samplingRateOptions.length) {
                if (samplingRateOptions[index] >= samplingRate) {
                    break;
                }
                index += 1;
            }
            samplingRate = samplingRateOptions[index];
        }
        return {
            ...data,
            samplingRate,
            startDate: importDateString(data.startDate),
            endDate: importDateString(data.endDate),
            modifyDate: importDateString(data.modifyDate),
            lastViewed: importDateString(data.lastViewed) ?? new Date(0),
        };
    }
}

export class ReportsApi extends CachedApi<
    Report,
    CreateReport,
    ReportPreview,
    ReportsQueryParams
> {
    private raw: RawReportsApi;
    rawAccess: RawAccessAdapter<Report, CreateReport>;
    dateString: (date: Date) => string;
    translate: TranslateService;

    constructor(private api: ApiService) {
        super();
        this.raw = new RawReportsApi(api);
        this.rawAccess = createRawAccess(this.raw, {});
        const dateTime = inject(DateTimeService);
        this.dateString = (date: Date) => dateTime.dateString(date);
        this.translate = inject(TranslateService);
    }

    attachTriggers(): void {
        this.api.pageViews.updated$
            .pipe(
                filter(
                    ({ feature, itemId }) =>
                        feature === 'reports' && isDefined(itemId),
                ),
            )
            .subscribe(({ itemId }) => this.loadItem(itemId));
    }

    async loadPreview(id: number): Promise<ReportPreview> {
        await ensureLoaded([this.api.devices, this.api.pins]);
        await waitForItem(this, id);
        const report = this.get(id);
        const devices = report.deviceIds.map((id) => this.api.devices.get(id));
        const isPinned = this.api.pins.has('report', report.id);
        const startDateString = this.dateString(report.startDate);
        const endDateString = report.endDate
            ? this.dateString(report.endDate)
            : this.translate.instant('reports.today');
        const dateRangeString = `${startDateString} - ${endDateString}`;
        const typeIcons = uniqueArray(report.charts.map((c) => c.type)).map(
            getTypeIcon,
        );
        return {
            type: 'report',
            id,
            title: report.name,
            subtitle: dateRangeString,
            details: [
                {
                    name: 'report.chart.type.plural',
                    value: '',
                    icon: typeIcons,
                },
            ],
            devices,
            isPinned,
            report,
        };
    }
}
