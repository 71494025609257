import { inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { hasRole, importDateString } from 'src/app/shared/common';
import { Message } from 'src/app/shared/models/can-database';
import {
    CreateDevice,
    Device,
    RatePlan,
    SimStatus,
    getSimStatusName,
} from 'src/app/shared/models/device';
import { Stats, StatsParams } from 'src/app/shared/models/page-view';
import { DevicePreview } from 'src/app/shared/models/preview';
import { environment } from 'src/environments/environment';
import { CustomerRole, DeviceStatus, urlBaseV2 } from '../constants';
import { ApiService } from './api.service';
import {
    CachedApi,
    RawAccessAdapter,
    createRawAccess,
    ensureLoaded,
    waitForItem,
} from './cached-api';
import { TopLevelQueryParams, TopLevelRouteApi } from './top-level-route-api';

export interface DevicesQueryParams extends TopLevelQueryParams {
    statusId?: DeviceStatus;
    modelId?: number;
    id?: number;
    customerId?: number;
    'id[ne]'?: number;
}

class RawDevicesApi extends TopLevelRouteApi<
    Device,
    CreateDevice,
    DevicesQueryParams
> {
    get path() {
        return 'devices';
    }

    constructor(api: ApiService) {
        super(api);
    }

    transformBackendData(data): Device {
        return {
            ...data,
            createdDate: importDateString(data.createdDate),
            lastOnline: importDateString(data.lastOnline),
            lastUpdated: importDateString(data.lastUpdated),
        };
    }
}

export class DevicesApi extends CachedApi<
    Device,
    CreateDevice,
    DevicePreview,
    DevicesQueryParams
> {
    private raw: RawDevicesApi;
    rawAccess: RawAccessAdapter<Device, CreateDevice>;
    translate: TranslateService;

    constructor(private api: ApiService) {
        super();
        this.raw = new RawDevicesApi(api);
        this.rawAccess = createRawAccess(this.raw, {
            disable: { get: false, create: false, update: false, delete: true },
        });
        this.translate = inject(TranslateService);
    }

    attachTriggers(): void {
        this.api.models.updated$.subscribe(async (model) =>
            this.current({ modelId: model.id }).forEach(({ id }) =>
                this.loadItem(id),
            ),
        );
    }

    /** Only listen to active devices. */
    listenActive(params: Omit<DevicesQueryParams, 'statusId'> = {}) {
        return this.listen({ ...params, statusId: DeviceStatus.Active });
    }

    /** Only query active devices. */
    queryActive(params: Omit<DevicesQueryParams, 'statusId'> = {}) {
        return this.current({ ...params, statusId: DeviceStatus.Active });
    }

    async loadPreview(id: number): Promise<DevicePreview> {
        await ensureLoaded([
            this.api.organizations,
            this.api.devices,
            this.api.models,
            this.api.pins,
        ]);
        await waitForItem(this, id);
        const device = this.get(id);
        const isPinned = this.api.pins.has('device', device.id);
        const displayName =
            (hasRole(CustomerRole.Dealer)
                ? device.identifier
                : device.userIdentifier) ?? device.identifier;
        const modelName =
            this.api.models.get(device.modelId)?.name ??
            this.translate.instant('model.none');
        const organization = this.api.organizations.get(device.customerId);
        const organizationName = organization?.name ?? '';
        const imageUrl = device.modelId
            ? urlBaseV2 + '/models/' + device.modelId + '/thumbnail'
            : environment.apiUrl + '/images/Placeholder-model.png';
        const isActive = device.statusId == DeviceStatus.Active;
        return {
            type: 'device',
            id,
            title: displayName,
            subtitle: modelName,
            imageUrl,
            details: [
                {
                    name: 'common.id',
                    value: id.toString(),
                    role: CustomerRole.Super,
                },
                {
                    name: 'device.serial_number',
                    value: device.serialNumber,
                },
                { name: 'organization', value: organizationName },
                {
                    name: 'status',
                    value: isActive ? 'status.active' : 'status.disabled',
                    icon: isActive ? 'check' : 'access-point-off',
                    color: isActive ? 'success' : 'warn',
                },
                {
                    name: 'device.sim.status',
                    value: getSimStatusName(device.simStatus),
                },
                {
                    name: 'device.software_version',
                    value: device.primarySoftwareVersion,
                },
            ],
            isPinned,
            device,
        };
    }

    getAllMessages(id: number) {
        return this.raw.retrieve<Message[]>(`${id}/all-messages`);
    }

    async getConnectionLog(id: number) {
        return this.raw.retrieveData(`${id}/connection-log`);
    }

    async getSimStatus(id: number) {
        return this.raw.retrieve<SimStatus>(`${id}/sim-status`);
    }

    async getRatePlans() {
        return this.raw.retrieve<RatePlan[]>('rate-plans');
    }

    async getStats(params: StatsParams) {
        return this.raw.retrieve<Stats>('connection-stats', params);
    }
}
