export class EvaluationContext {
    private _signalValues: Map<number, unknown>;

    constructor(signalValues: Map<number, unknown>) {
        this._signalValues = signalValues;
    }

    getSignalValue(signalId: number) {
        return this._signalValues.get(signalId);
    }
}
