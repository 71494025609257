import {
    CreateWidget,
    Widget,
    WidgetsQueryParams,
} from 'src/app/shared/models/widget';
import { ApiService } from './api.service';
import { TopLevelFileRouteApi } from './top-level-file-route-api';
import {
    CachedApi,
    RawAccessAdapter,
    createRawAccess,
    ensureLoaded,
    waitForItem,
} from './cached-api';
import { WidgetPreview } from 'src/app/shared/models/preview';

export class RawCustomWidgetsApi extends TopLevelFileRouteApi<
    Widget,
    CreateWidget,
    WidgetsQueryParams
> {
    get path() {
        return 'widgets';
    }

    get fileSubPath() {
        return 'script';
    }

    constructor(api: ApiService) {
        super(api);
    }
}

export class CustomWidgetsApi extends CachedApi<
    Widget,
    CreateWidget,
    WidgetPreview,
    WidgetsQueryParams
> {
    private raw: RawCustomWidgetsApi;
    rawAccess: RawAccessAdapter<Widget, CreateWidget>;

    constructor(private api: ApiService) {
        super();
        this.raw = new RawCustomWidgetsApi(api);
        this.rawAccess = createRawAccess(this.raw, {});
    }

    async loadPreview(id: number): Promise<WidgetPreview> {
        await ensureLoaded([this.api.widgets, this.api.pins]);
        await waitForItem(this, id);
        const widget = this.get(id);
        const isPinned = this.api.pins.has('widget', widget.id);
        return {
            type: 'widget',
            id,
            title: widget.name,
            subtitle: widget.customerName,
            details: [],
            isPinned,
            widget,
        };
    }

    upload(id: number, data: Blob) {
        return this.raw.upload(id, data);
    }

    download(id: number) {
        return this.raw.download(id);
    }
}
