import { ApiService } from './api.service';
import { BasicRouteApi } from './basic-route-api';

interface FeedbackParams {
    message: string;
    context: {
        hostname: string;
        url: string;
        userEmail: string;
        userId: number;
    };
}

export class FeedbackApi extends BasicRouteApi {
    get path() {
        return 'feedback';
    }

    constructor(api: ApiService) {
        super(api);
    }

    sendUserFeedback(params: FeedbackParams) {
        return this.send('send', params);
    }
}
