<form [formGroup]="form" class="settings-form">
    <mat-form-field>
        <input formControlName="mg_title1" matInput placeholder="Title 1" />
    </mat-form-field>

    <app-text-area-select [parent]="form" name="mg_data1">
        Data 1
    </app-text-area-select>

    <mat-form-field>
        <input formControlName="mg_unit1" matInput placeholder="Unit 1" />
    </mat-form-field>

    <app-text-area-select [parent]="form" name="mg_bg1">
        Background color 1
    </app-text-area-select>

    <!---->

    <mat-form-field>
        <input formControlName="mg_title2" matInput placeholder="Title 2" />
    </mat-form-field>

    <app-text-area-select [parent]="form" name="mg_data2">
        Data 2
    </app-text-area-select>

    <mat-form-field>
        <input formControlName="mg_unit2" matInput placeholder="Unit 2" />
    </mat-form-field>

    <app-text-area-select [parent]="form" name="mg_bg2">
        Background color 2
    </app-text-area-select>

    <!---->

    <mat-form-field>
        <input formControlName="mg_title3" matInput placeholder="Title 3" />
    </mat-form-field>

    <app-text-area-select [parent]="form" name="mg_data3">
        Data 3
    </app-text-area-select>

    <mat-form-field>
        <input formControlName="mg_unit3" matInput placeholder="Unit 3" />
    </mat-form-field>

    <app-text-area-select [parent]="form" name="mg_bg3">
        Background color 3
    </app-text-area-select>

    <!---->

    <mat-form-field>
        <input formControlName="mg_title4" matInput placeholder="Title 4" />
    </mat-form-field>

    <app-text-area-select [parent]="form" name="mg_data4">
        Data 4
    </app-text-area-select>

    <mat-form-field>
        <input formControlName="mg_unit4" matInput placeholder="Unit 4" />
    </mat-form-field>

    <app-text-area-select [parent]="form" name="mg_bg4">
        Background color 4
    </app-text-area-select>

    <!---->

    <mat-form-field>
        <input formControlName="mg_title5" matInput placeholder="Title 5" />
    </mat-form-field>

    <app-text-area-select [parent]="form" name="mg_data5">
        Data 5
    </app-text-area-select>

    <mat-form-field>
        <input formControlName="mg_unit5" matInput placeholder="Unit 5" />
    </mat-form-field>

    <app-text-area-select [parent]="form" name="mg_bg5">
        Background color 5
    </app-text-area-select>

    <!---->

    <mat-form-field>
        <input formControlName="mg_title6" matInput placeholder="Title 6" />
    </mat-form-field>

    <app-text-area-select [parent]="form" name="mg_data6">
        Data 6
    </app-text-area-select>

    <mat-form-field>
        <input formControlName="mg_unit6" matInput placeholder="Unit 6" />
    </mat-form-field>

    <app-text-area-select [parent]="form" name="mg_bg6">
        Background color 6
    </app-text-area-select>
</form>
