import { inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CreateModule, Module } from 'src/app/shared/models/module';
import { ModulePreview } from 'src/app/shared/models/preview';
import { ApiService } from './api.service';
import {
    CachedApi,
    RawAccessAdapter,
    createRawAccess,
    ensureLoaded,
    waitForItem,
} from './cached-api';
import { TopLevelQueryParams, TopLevelRouteApi } from './top-level-route-api';

export interface ModulesQueryParams extends TopLevelQueryParams {
    modelId?: number;
}

class RawModulesApi extends TopLevelRouteApi<
    Module,
    CreateModule,
    ModulesQueryParams
> {
    get path() {
        return 'modules';
    }

    constructor(api: ApiService) {
        super(api);
    }
}

export class ModulesApi extends CachedApi<
    Module,
    CreateModule,
    ModulePreview,
    ModulesQueryParams
> {
    private raw: RawModulesApi;
    rawAccess: RawAccessAdapter<Module, CreateModule>;
    translate: TranslateService;

    constructor(private api: ApiService) {
        super();
        this.raw = new RawModulesApi(api);
        this.rawAccess = createRawAccess(this.raw, {});
        this.translate = inject(TranslateService);
    }

    async loadPreview(id: number): Promise<ModulePreview> {
        await ensureLoaded([this.api.models]);
        await waitForItem(this, id);
        const module = this.get(id);
        const model = this.api.models.get(module.modelId);
        return {
            type: 'module',
            id,
            title: module.name,
            subtitle: model?.name,
            details: [
                { name: 'module.key', value: module.key },
                { name: 'module.order', value: module.order.toString() },
            ],
            model,
            module,
        };
    }

    /**
     * Make the given module the primary one for its model.
     */
    async assignPrimary(id: number) {
        const module = this.get(id);
        const modulesOfModel = this.current({ modelId: module.modelId });
        modulesOfModel.sort((a, b) => {
            if (a.id == id) {
                return -1;
            } else if (b.id == id) {
                return 1;
            } else {
                return a.order - b.order;
            }
        });
        for (let order = 0; order < modulesOfModel.length; order++) {
            this.update(modulesOfModel[order].id, { order });
        }
    }
}
