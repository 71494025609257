import { importDateString } from 'src/app/shared/common';
import {
    Announcement,
    CreateAnnouncement,
} from 'src/app/shared/models/announcement';
import { AnnouncementPreview } from 'src/app/shared/models/preview';
import { ApiService } from './api.service';
import { BasicRouteApi } from './basic-route-api';
import {
    CachedApi,
    RawAccessAdapter,
    createRawAccess,
    waitForItem,
} from './cached-api';
import { TopLevelQueryParams } from './top-level-route-api';
import { TranslateService } from '@ngx-translate/core';
import { DateTimeService } from '../date-time.service';
import { inject } from '@angular/core';

interface AnnouncementsQueryParams extends TopLevelQueryParams {
    unread?: boolean;
    isActive?: boolean;
}

export class RawAnnouncementsApi extends BasicRouteApi {
    get path() {
        return 'announcements';
    }

    constructor(api: ApiService) {
        super(api);
    }

    async query(params: { offset: number; limit: number }) {
        return (await this.retrieve<Record<string, unknown>[]>('', params))
            .filter(({ content }) => content != null)
            .map(({ id, timestamp, content, isActive, unread }) => ({
                id,
                timestamp: importDateString(timestamp.toString()),
                content,
                isActive,
                unread,
            })) as Announcement[];
    }

    async recordImpression(
        id: number,
    ): Promise<{ success: boolean; message: string }> {
        return (await this.send(`${id}/impression`, {})) as {
            success: boolean;
            message: string;
        };
    }
}

export class AnnouncementsApi extends CachedApi<
    Announcement,
    CreateAnnouncement,
    AnnouncementPreview,
    AnnouncementsQueryParams
> {
    raw: RawAnnouncementsApi;
    rawAccess: RawAccessAdapter<Announcement, CreateAnnouncement>;
    dateString: (date: Date) => string;
    translate: TranslateService;

    constructor(api: ApiService) {
        super();
        this.raw = new RawAnnouncementsApi(api);
        this.rawAccess = createRawAccess(this.raw, {
            disable: { get: true, create: true, update: true, delete: true },
        });
        const dateTime = inject(DateTimeService);
        this.dateString = (date: Date) => dateTime.dateString(date);
        this.translate = inject(TranslateService);
    }

    async loadPreview(id: number): Promise<AnnouncementPreview> {
        await waitForItem(this, id);
        const announcement = this.get(id);
        const content = announcement.content;
        const langOptions = [
            this.translate.currentLang,
            'en',
            Object.keys(content)[0],
        ];
        const lang = langOptions.find((lang) => content[lang]);
        return {
            type: 'announcement',
            id,
            title: this.dateString(announcement.timestamp),
            subtitle: content[lang],
            announcement,
        };
    }

    markAsRead(id: number) {
        this.assertLoaded();
        this.raw.recordImpression(id);
        // Update the unread status in memory. No need to reload from backend.
        const announcement = this.get(id);
        announcement.unread = false;
        this.broadcastUpdate();
    }
}
