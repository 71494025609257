import { Widget, WidgetType } from '../widget';
import { WidgetSettingType } from '../widget-settings';

export class TwoActionWidget extends Widget {
    type = WidgetType.TwoActionWidget;

    constructor(settings?) {
        super(
            [
                {
                    id: 'title',
                    type: WidgetSettingType.Text,
                    name: 'widget.settings.title',
                    previewValue: 'Counter',
                    defaultValue: '',
                },
                {
                    id: 'value',
                    type: WidgetSettingType.Calculated,
                    name: 'common.current_status',
                    previewValue: '',
                },

                {
                    id: 'has_lookup',
                    type: WidgetSettingType.Checkbox,
                    name: 'widget.settings.lookup_table.enable',
                    previewValue: true,
                    defaultValue: false,
                },
                {
                    id: 'variables',
                    type: WidgetSettingType.Text,
                    name: 'widget.settings.lookup_table',
                    previewValue: 'Off',
                    defaultValue: 'Off',
                },

                {
                    id: 'callback',
                    type: WidgetSettingType.Calculated,
                    name: 'common.send',
                    previewValue: '',
                    defaultValue: '',
                },
                {
                    id: 'left_button',
                    type: WidgetSettingType.Select,
                    name: 'widget.settings.button.left.color',
                    previewValue: 'blue',
                    defaultValue: 'blue',
                    config: {
                        options: [
                            { value: 'red', name: 'color.red' },
                            { value: 'orange', name: 'color.orange' },
                            { value: 'yellow', name: 'color.yellow' },
                            { value: 'green', name: 'color.green' },
                            { value: 'blue', name: 'color.blue' },
                            { value: 'purple', name: 'color.purple' },
                            { value: 'gray', name: 'color.gray' },
                            { value: 'black', name: 'color.black' },
                        ],
                    },
                },
                {
                    id: 'left_button_text',
                    type: WidgetSettingType.Text,
                    name: 'widget.settings.button.left.text',
                    previewValue: '-',
                    defaultValue: '-',
                },
                {
                    id: 'right_button',
                    type: WidgetSettingType.Select,
                    name: 'widget.settings.button.right.color',
                    previewValue: 'blue',
                    defaultValue: 'blue',
                    config: {
                        options: [
                            { value: 'red', name: 'color.red' },
                            { value: 'orange', name: 'color.orange' },
                            { value: 'yellow', name: 'color.yellow' },
                            { value: 'green', name: 'color.green' },
                            { value: 'blue', name: 'color.blue' },
                            { value: 'purple', name: 'color.purple' },
                            { value: 'gray', name: 'color.gray' },
                            { value: 'black', name: 'color.black' },
                        ],
                    },
                },
                {
                    id: 'right_button_text',
                    type: WidgetSettingType.Text,
                    name: 'widget.settings.button.right.text',
                    previewValue: '+',
                    defaultValue: '+',
                },
                {
                    id: 'show_buttons',
                    type: WidgetSettingType.Select,
                    name: 'widget.settings.button.style',
                    previewValue: 'triangle',
                    config: {
                        options: [
                            {
                                value: 'hide',
                                name: 'widget.settings.button.style.hide_buttons',
                            },
                            {
                                value: 'triangle',
                                name: 'widget.settings.button.style.triangle',
                            },
                            {
                                value: 'square',
                                name: 'widget.settings.button.style.square',
                            },
                        ],
                    },
                },
                {
                    id: 'status_text',
                    type: WidgetSettingType.Text,
                    name: 'widget.settings.default_value',
                    previewValue: '0',
                },
            ],
            settings
        );
    }
}
