import { clone } from 'src/app/shared/common';
import {
    IWidgetSubTopic,
    IWidgetTopicParse,
    regexpDatasourceValue,
    regexpDatasourceValueGroup,
} from './common.model';
import { Datasource } from './datasource';
import {
    RepeatedConfig,
    WidgetSettingDefinition,
    WidgetSettingType,
    getSettingValue,
} from './widget-settings';

const DEFAULT_TOPIC_LENGTH = 5;
export abstract class Widget {
    uuid: string;
    type: string;
    settings = {};
    customWidgetId?: number;
    height = 0;

    // TODO: Can we remove these two fields?
    calcProps: {
        [key: string]:
            | IWidgetTopicParse
            | IWidgetTopicParse[]
            | IWidgetSubTopic;
    };
    topicLength = DEFAULT_TOPIC_LENGTH; // e.g. for WidgetHex is 3;

    get serialized(): SerializedWidget {
        const serialized: SerializedWidget = {
            uuid: this.uuid,
            type: this.type,
            settings: clone(this.settings),
        };
        if (this.customWidgetId) {
            serialized.id = this.customWidgetId;
        }
        if (this.height > 0) {
            serialized.height = this.height;
        }
        return serialized;
    }

    constructor(
        public settingDefinitions: WidgetSettingDefinition[] = [],
        settings?,
    ) {
        this.setDefaultSettingValues(settings);
    }

    setDefaultSettingValues(settings?) {
        settings ??= {};
        // Set the initial values for all the settings.
        for (const settingDefinition of this.settingDefinitions) {
            let value = settingDefinition.defaultValue;
            // Repeated settings use an array of values, so they require special
            // handling.
            if (settingDefinition.type == WidgetSettingType.Repeated) {
                value = settings[settingDefinition.id];
                if (value) {
                    if (typeof value === 'string') {
                        // Some old repeated setting values might not have been
                        // saved as plain values.
                        value = [{ value }];
                    } else if (
                        value.length > 0 &&
                        typeof value[0] === 'string'
                    ) {
                        // The old repeated values was an array of strings.
                        // The new repeated values are an array of objects.
                        value = value.map((value) => ({ value }));
                    }
                } else {
                    const config = settingDefinition.config as RepeatedConfig;
                    // Generate a default value from the children of the
                    // Repeated.
                    if (config) {
                        value = [
                            Object.fromEntries(
                                config.children.map((c) => [
                                    c.id,
                                    getSettingValue(c, undefined),
                                ]),
                            ),
                        ];
                    }
                }
            } else {
                if (settingDefinition.id in settings) {
                    value = settings[settingDefinition.id];
                }
            }
            this.settings[settingDefinition.id] = value;
        }
    }

    getSettingDefinition(id: string) {
        return this.settingDefinitions.find((s) => s.id == id);
    }

    /**
     * Get the current setting values, including datasource objects.
     */
    getSettingValues(datasources: Datasource[]) {
        const firstDatasource = datasources[0];
        const values = {};
        for (const settingDefinition of this.settingDefinitions) {
            const settingId = settingDefinition.id;
            let value = this.settings[settingId];
            // Datasource settings need special handling.
            if (settingDefinition.type == WidgetSettingType.Datasource) {
                let datasource = datasources.find((d) => d.uuid == value);
                if (datasource == undefined) {
                    if (firstDatasource) {
                        datasource = firstDatasource;
                        // Update the setting to use the first datasource UUID.
                        this.settings[settingId] = datasource.uuid;
                    } else {
                        this.settings[settingId] = null;
                    }
                }
                value = datasource ? datasource.sandboxDatasource : null;
            }
            values[settingId] = value;
        }
        return values;
    }

    /**
     * @param data
     * topicObj - obj with datasourceName, widgetValueType props;
     */
    parseTopic_(data): IWidgetTopicParse {
        const { busNames, canNames, topicStr, topicObj } = data;
        const defaultResult = { source: null, code: topicStr };

        if (!topicStr) {
            return defaultResult;
        }

        const groups = topicStr.match(regexpDatasourceValueGroup);

        if (!groups) {
            return defaultResult;
        }
        const source = topicStr.match(regexpDatasourceValueGroup).groups.source;
        const resUI = source.match(regexpDatasourceValue);

        if (!resUI) {
            return defaultResult;
        }

        const regex = /(mon|ctrl)\/([a-zA-Z]*)([0-9]*)\/([a-fA-F0-9]*)/i;
        const m = regex.exec(resUI[1]); // resUI[1] from start it is topic mon/0/...

        let action;
        let bus_id;
        let can_id;

        let code;
        let busN;
        let canN;
        let newSourceStr;

        // if already parsed
        if (!m && resUI.length === this.topicLength) {
            action = resUI[1];
            busN = resUI[2];
            canN = resUI[3];
            code = topicStr;

            if (this.topicLength === DEFAULT_TOPIC_LENGTH) {
                // for now it is only for WidgetHex - so skip next condition
                // because it can be any json obj
                if (
                    !Object.hasOwn(canNames.ids, canN) ||
                    !Object.hasOwn(busNames, busN)
                ) {
                    return defaultResult;
                }
            }

            topicObj.datasourceName = resUI[0];
            topicObj.widgetValueType = resUI[4];

            const can = canN ? `["${canN}"]` : '';
            const value = topicObj.widgetValueType
                ? `["${topicObj.widgetValueType}"]`
                : '';

            newSourceStr = `datasources["${topicObj.datasourceName}"]["${action}"]["${busN}"]${can}${value}`;
        } else if (m && m.length === 5) {
            action = m[1];
            bus_id = m[3];
            can_id = m[4];

            // The CAN ID may be in hex format, so let's check and convert it to integer
            if (isNaN(can_id)) {
                can_id = parseInt(can_id, 16);
            }

            busN = busNames[bus_id];
            canN = canNames.names[can_id];

            const can = canN ? `["${canN}"]` : '';
            const value = topicObj.widgetValueType
                ? `["${topicObj.widgetValueType}"]`
                : '';

            newSourceStr = `datasources["${topicObj.datasourceName}"]["${action}"]["${busN}"]${can}${value}`;

            const reg = /(datasources\[.*\])/gi;
            const res = topicStr ? reg.exec(topicStr) : null;
            code = res ? topicStr.replaceAll(res[0], newSourceStr) : '';
        } else {
            return defaultResult;
        }

        return {
            source: {
                full: newSourceStr,
                name: topicObj.datasourceName,
                permission: action,
                bus: busN,
                can: canN,
                variable: topicObj.widgetValueType,
            },
            code,
        };
    }

    parseTopic({
        busNames,
        canNames,
    }: {
        busNames: { [key: string | number]: string | number };
        canNames: { [key: string]: string };
    }) {
        Object.keys(this.calcProps).forEach((prop) => {
            this.calcProps[prop] = this.parseTopic_({
                busNames,
                canNames,
                topicStr: this.settings[prop],
                topicObj: this,
            });
            this.settings[prop] = (
                this.calcProps[prop] as IWidgetTopicParse
            ).code;
        });
    }

    initialDatasourceParse(valueStr): string[] {
        if (!valueStr) {
            return null;
        }
        const match = valueStr.match(regexpDatasourceValueGroup);
        if (!match) {
            return null;
        }
        return match.groups.source.match(regexpDatasourceValue);
    }

    isItForThisWidget(obj, props: IWidgetTopicParse): boolean {
        const sourceName = props.source.name;
        const perm = props.source.permission;
        const bus = props.source.bus;
        const can = props.source.can;

        return (
            obj &&
            Object.hasOwn(obj, sourceName) &&
            Object.hasOwn(obj[sourceName], perm) &&
            Object.hasOwn(obj[sourceName][perm], bus) &&
            Object.hasOwn(obj[sourceName][perm][bus], can)
        );
    }

    getWidgetValue(match: string[]) {
        if (!match) {
            return null;
        }

        return match[2] ? match[match.length - 1] : match[2];
    }
}

export enum WidgetCategory {
    Custom = 'custom',
    Display = 'display',
    Control = 'control',
    Graph = 'graph',
}

export enum WidgetType {
    GaugeWidget = 'gauge',
    ButtonWidget = 'button',
    SwitchWidget = 'slide_switch',
    NumberSenderWidget = 'numericUpDown',
    INDICATOR = 'indicator',
    LineGraph = 'sparkline',
    Text = 'text_widget',
    PictureWidget = 'picture',
    MapWidget = 'google_map',
    POINTER_WIDGET = 'pointer',
    InteractiveIndicatorWidget = 'interactive_indicator',
    TextSenderWidget = 'Textbox',
    MONITOR_GRID_WIDGET = 'monitor_grid',
    SINGLE_MONITOR_WIDGET = 'single_monitor',
    TwoActionWidget = 'twoAction',
    DonutWidget = 'donut_widget',
    PieWidget = 'pie_widget',
    BarGraph = 'barGraph',
    HexWidget = 'hex_widget',
    CUSTOM_WIDGET = 'custom_widget',
    LinkWidget = 'link_widget',
    RebootCommandWidget = 'reboot_command',
}

export const WidgetTypeName = {
    [WidgetType.GaugeWidget]: 'widget.type.gauge',
    [WidgetType.ButtonWidget]: 'widget.type.button',
    [WidgetType.SwitchWidget]: 'widget.type.slide_switch',
    [WidgetType.NumberSenderWidget]: 'widget.type.number_sender',
    [WidgetType.INDICATOR]: 'widget.type.indicator',
    [WidgetType.LineGraph]: 'widget.type.line_graph',
    [WidgetType.Text]: 'widget.type.text',
    [WidgetType.PictureWidget]: 'widget.type.picture',
    [WidgetType.MapWidget]: 'widget.type.map',
    [WidgetType.POINTER_WIDGET]: 'widget.type.pointer',
    [WidgetType.InteractiveIndicatorWidget]:
        'widget.type.interactive_indicator',
    [WidgetType.TextSenderWidget]: 'widget.type.text_sender',
    [WidgetType.MONITOR_GRID_WIDGET]: 'widget.type.monitor_grid',
    [WidgetType.SINGLE_MONITOR_WIDGET]: 'widget.type.single_monitor',
    [WidgetType.TwoActionWidget]: 'widget.type.two_action',
    [WidgetType.DonutWidget]: 'widget.type.donut',
    [WidgetType.PieWidget]: 'widget.type.pie',
    [WidgetType.BarGraph]: 'widget.type.bar_graph',
    [WidgetType.HexWidget]: 'widget.type.hex',
    [WidgetType.CUSTOM_WIDGET]: 'widget.type.custom',
    [WidgetType.LinkWidget]: 'widget.type.link',
    [WidgetType.RebootCommandWidget]: 'widget.type.reboot_command',
};

export const widgetCategoryWidgets: Partial<Record<WidgetCategory, string[]>> =
    {
        [WidgetCategory.Display]: [
            WidgetType.Text,
            WidgetType.INDICATOR,
            WidgetType.MapWidget,
            WidgetType.PictureWidget,
            WidgetType.LinkWidget,
            WidgetType.MONITOR_GRID_WIDGET,
            WidgetType.SINGLE_MONITOR_WIDGET,
        ],
        [WidgetCategory.Control]: [
            WidgetType.ButtonWidget,
            WidgetType.InteractiveIndicatorWidget,
            WidgetType.TextSenderWidget,
            WidgetType.NumberSenderWidget,
            WidgetType.SwitchWidget,
            WidgetType.TwoActionWidget,
            WidgetType.HexWidget,
            WidgetType.RebootCommandWidget,
        ],
        [WidgetCategory.Graph]: [
            WidgetType.LineGraph,
            WidgetType.BarGraph,
            WidgetType.DonutWidget,
            WidgetType.PieWidget,
            WidgetType.POINTER_WIDGET,
            WidgetType.GaugeWidget,
        ],
    };

export interface SerializedWidget {
    uuid: string;
    type: string;
    settings;
    id?: number | string;
    height?: number;
}
