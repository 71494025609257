import { BehaviorSubject, Observable, Subject, combineLatest } from 'rxjs';
import { filter, map, takeWhile } from 'rxjs/operators';
import { getCacheKey } from 'src/app/shared/cache';
import {
    filterBy,
    isDefined,
    isEmpty,
    offsetAndLimitBy,
    searchBy,
    sortBy,
    update,
} from 'src/app/shared/common';
import {
    CreateResult,
    DeleteResult,
    UpdateResult,
} from './top-level-route-api';

const CHUNK_SIZE = 500;

export interface RawAccessAdapter<GetType, CreateType> {
    loadChunk(chunkId: number, chunkSize: number): Promise<GetType[]>;
    get(id: number): Promise<GetType>;
    create(data: CreateType): Promise<CreateResult>;
    update(id: number, updates: Partial<CreateType>): Promise<UpdateResult>;
    delete(ids: number[]): Promise<DeleteResult>;
}

export const createRawAccess = <CreateType>(
    rawApi,
    options: {
        disable?: {
            get: boolean;
            create: boolean;
            update: boolean;
            delete: boolean;
        };
        extraQueryParams?;
    },
) => {
    options.disable ??= {
        get: false,
        create: false,
        update: false,
        delete: false,
    };
    options.extraQueryParams ??= {};
    return {
        loadChunk: async (chunkId: number, chunkSize: number) =>
            await rawApi.query({
                ...options.extraQueryParams,
                offset: chunkSize * chunkId,
                limit: chunkSize,
            }),
        get: async (id: number) => {
            if (options.disable.get) {
                throw Error('Get not supported');
            }
            return await rawApi.get(id);
        },
        create: async (data: CreateType) => {
            if (options.disable.create) {
                throw Error('Create not supported');
            }
            return await rawApi.create(data);
        },
        update: async (id: number, updates: Partial<CreateType>) => {
            if (options.disable.update) {
                throw Error('Update not supported');
            }
            return await rawApi.update(id, updates);
        },
        delete: async (ids: number[]) => {
            if (options.disable.delete) {
                throw Error('Delete not supported');
            }
            return await rawApi.delete(ids);
        },
    };
};

export class CachedApi<GetType, CreateType, PreviewType, QueryParamsType> {
    private _isLoading = false;
    private cachedKey = '';
    protected cache: Record<number, GetType> = {};
    private current$ = new BehaviorSubject<GetType[]>(null);
    rawAccess: RawAccessAdapter<GetType, CreateType> = null;
    // This is triggered whenever an item has been updated on the backend.
    // Used for triggers on other cached APIs.
    updated$ = new Subject<GetType>();

    get isLoaded() {
        return this.activeKey === this.cachedKey;
    }

    get isLoading() {
        return this._isLoading;
    }

    get activeKey() {
        return getCacheKey();
    }

    /**
     * Load items and return an observable that will watch for updates.
     */
    listen(params: Partial<QueryParamsType> = {}): Observable<GetType[]> {
        if (!this.isLoaded && !this._isLoading) {
            this.load();
        }
        const subject = this.current$.pipe(filter((item) => item != null));
        if (isEmpty(params)) {
            return subject;
        }
        return subject.pipe(map(() => this.currentWithParams(params)));
    }

    current(params: Partial<QueryParamsType> = {}): GetType[] {
        return this.currentWithParams(params);
    }

    private currentWithParams(params: Partial<QueryParamsType>): GetType[] {
        params = { ...params }; // Don't mutate the passed in params.
        let searchTerm = '';
        let searchFields = [];
        if (isDefined(params['searchTerm'])) {
            searchTerm = params['searchTerm'];
            searchFields = params['searchFields'].split(',');
            delete params['searchTerm'];
            delete params['searchFields'];
        }
        let sortField = null;
        let descendingOrder = false;
        if (isDefined(params['sort'])) {
            sortField = params['sort'];
            descendingOrder = params['sortOrder'] === 'DESC';
            delete params['sort'];
            delete params['sortOrder'];
        }
        let offset = null;
        if (isDefined(params['offset'])) {
            offset = params['offset'];
            delete params['offset'];
        }
        let limit = null;
        if (isDefined(params['limit'])) {
            limit = params['limit'];
            delete params['limit'];
        }
        for (const filter of Object.keys(params)) {
            if (filter.includes('[')) {
                throw new Error(
                    `Filter "${filter}" is not supported by CachedApi`,
                );
            }
        }
        const output = Object.values(this.cache)
            .filter(filterBy(params))
            .filter(searchBy(searchFields, searchTerm));
        if (sortField) {
            output.sort(sortBy(sortField, descendingOrder));
        }
        return output.filter(offsetAndLimitBy(offset, limit));
    }

    get(id: number) {
        return this.cache[id] ?? null;
    }

    async create(data: CreateType): Promise<CreateResult> {
        const result = await this.rawAccess.create(data);
        if (result.success) {
            await this.loadItem(result.id);
        }
        return result;
    }

    update(id: number, updates: Partial<CreateType>) {
        const item = this.get(id);
        if (!item) {
            throw Error(`Unknown ID: ${id}`);
        }
        // Update cached version.
        update(item, updates);
        this.broadcastUpdate();
        // Sync changes to the backend.
        this.rawAccess.update(id, updates).then(() => {
            this.updated$.next(item);
        });
    }

    delete(ids: number[]) {
        ids.forEach((id) => delete this.cache[id]);
        this.broadcastUpdate();
        this.rawAccess.delete(ids);
    }

    reset() {
        this.cache = {};
        this.cachedKey = '';
    }

    getId(item: GetType): number {
        // Override this method for `GetType` that don't have an id field.
        return item['id'];
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loadPreview(id: number): Promise<PreviewType> {
        throw new Error('The child class must implement this function.');
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    attachTriggers(): void {}

    protected async load() {
        this._isLoading = true;
        this.cache = {};
        let chunkId = 0;
        let moreChunks = false;
        do {
            const chunk = await this.rawAccess.loadChunk(chunkId, CHUNK_SIZE);
            moreChunks = chunk.length == CHUNK_SIZE;
            chunk.forEach((item) => (this.cache[this.getId(item)] = item));
            if (moreChunks) {
                this.broadcastUpdate(); // Broadcast an temporary value.
            }
            chunkId++;
        } while (moreChunks);
        this.cachedKey = this.activeKey;
        this._isLoading = false;
        // Broadcast final complete results.
        this.broadcastUpdate();
    }

    protected async loadItem(id: number) {
        this.cache[id] = await this.rawAccess.get(id);
        this.broadcastUpdate();
    }

    protected broadcastUpdate() {
        this.current$.next(Object.values(this.cache));
    }

    protected assertLoaded() {
        if (!this.isLoaded) {
            throw new Error(
                'Cache data not loaded. Please call .listen() before trying to access cache.',
            );
        }
    }
}

/**
 * A convenient way to make sure all the given cached APIs are completely loaded
 * before accessing them.
 */
export const ensureLoaded = (apis: { listen; isLoading: boolean }[]) =>
    new Promise((resolve) =>
        combineLatest(
            apis.map((api) => api.listen().pipe(filter(() => !api.isLoading))),
        ).subscribe(resolve),
    );

/**
 * Wait for the item to load, or throw an error if the API completes loading and
 * doesn't include the item.
 */
export const waitForItem = (
    api: {
        listen: () => Observable<unknown>;
        isLoading: boolean;
        get: (number) => unknown;
    },
    id: number,
) => {
    let isWaiting = true;
    return new Promise((resolve, reject) => {
        api.listen()
            .pipe(takeWhile(() => isWaiting))
            .subscribe(() => {
                const item = api.get(id);
                if (item != null) {
                    isWaiting = false;
                    resolve(item);
                }
                if (!api.isLoading) {
                    isWaiting = false;
                    reject(new Error('Item does not exist!'));
                }
            });
    });
};
