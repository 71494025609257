import { GaugeType } from '../common.model';
import { Widget, WidgetType } from '../widget';
import { WidgetSettingType } from '../widget-settings';

export class GaugeWidget extends Widget {
    type = WidgetType.GaugeWidget;

    constructor(settings?) {
        super(
            [
                {
                    id: 'type',
                    type: WidgetSettingType.Select,
                    name: 'type',
                    previewValue: GaugeType.ORIGINAL,
                    defaultValue: GaugeType.ORIGINAL,
                    config: {
                        options: [
                            { value: GaugeType.ORIGINAL, name: 'Original' },
                            { value: GaugeType.CUSTOM, name: 'Custom' },
                        ],
                    },
                },
                {
                    id: 'title',
                    type: WidgetSettingType.Text,
                    name: 'widget.settings.title',
                    previewValue: '',
                },
                {
                    id: 'value',
                    type: WidgetSettingType.Calculated,
                    name: 'widget.settings.value',
                    previewValue: 0,
                    defaultValue: 0,
                },
                {
                    id: 'unit',
                    type: WidgetSettingType.Text,
                    name: 'widget.settings.unit',
                    previewValue: 'seconds',
                    defaultValue: '',
                },
                {
                    id: 'min_value',
                    type: WidgetSettingType.Number,
                    name: 'common.minimum',
                    previewValue: 0,
                    defaultValue: 0,
                },
                {
                    id: 'max_value',
                    type: WidgetSettingType.Number,
                    name: 'common.maximum',
                    previewValue: 100,
                    defaultValue: 100,
                },
                {
                    id: 'highlights',
                    type: WidgetSettingType.Repeated,
                    name: 'widget.settings.highlights',
                    previewValue: [],
                    defaultValue: [],
                    config: {
                        children: [
                            {
                                id: 'from',
                                type: WidgetSettingType.Number,
                                name: 'common.from',
                                previewValue: 50,
                                defaultValue: 50,
                            },
                            {
                                id: 'to',
                                type: WidgetSettingType.Number,
                                name: 'common.to',
                                previewValue: 70,
                                defaultValue: 70,
                            },
                            {
                                id: 'color',
                                type: WidgetSettingType.Text,
                                name: 'widget.settings.color',
                                previewValue: '#66edff',
                            },
                        ],
                    },
                },
                {
                    id: 'animation',
                    type: WidgetSettingType.Checkbox,
                    name: 'widget.settings.animation',
                    previewValue: false,
                    defaultValue: false,
                },
            ],
            settings
        );
    }
}
