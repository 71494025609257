import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { cloneArray } from '../common';

@Pipe({
    name: 'orderByTranslation',
})
export class OrderByTranslationPipe implements PipeTransform {
    constructor(private translate: TranslateService) {}

    transform(array: Array<any>, key = 'name'): Array<any> {
        const clonedArray = cloneArray(array);
        clonedArray.sort((a: any, b: any) => {
            const translatedA = this.translate.instant(a[key]);
            const translatedB = this.translate.instant(b[key]);
            return translatedA.localeCompare(translatedB);
        });
        return clonedArray;
    }
}
