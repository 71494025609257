import { v4 as uuidv4 } from 'uuid';
import {
    chunkArray,
    setNestedValue,
    union,
    uniqueArray,
} from '../shared/common';
import { log } from '../shared/log';
import { EvaluationContext } from '../shared/models/expression/evaluation-context';
import { Expression } from '../shared/models/expression/expression';
import { ExpressionKind } from '../shared/models/expression/kind';
import { ExpressionNull } from '../shared/models/expression/null';

export interface ChartMessage {
    id: number;
    descriptor: string;
    signals: ChartSignal[];
    deviceIds: number[];
}

export interface ChartSignal {
    id: number;
    name: string;
    unit: string;
    dataType: string;
}

export class CalculatedSignal {
    uuid: string;
    name: string;
    deviceIds: number[];
    expression: Expression;

    constructor(name: string, deviceIds: number[], expression: Expression) {
        this.uuid = uuidv4();
        this.name = name;
        this.deviceIds = deviceIds;
        this.expression = expression;
    }
}

export interface SignalRawData {
    deviceId: number;
    signalId: number;
    values: Map<number, any>;
}

type SignalDevicesValues = Record<string, any>;
type SignalsSnapshot = Record<string, SignalDevicesValues>;

export enum ChartType {
    LINE,
    TABLE,
    MAP,
}

export function getTypeIcon(type: ChartType) {
    switch (type) {
        case ChartType.LINE:
            return 'chart-timeline-variant';
        case ChartType.TABLE:
            return 'table';
        case ChartType.MAP:
            return 'map';
    }
}

export interface Progress {
    completed: number;
    total: number;
}

export interface ChartThreshold {
    yAxis: number;
}

export class Chart {
    id: number;
    title: string;
    type: ChartType;
    calculatedSignals: CalculatedSignal[];
    filter: Expression;
    chartPoints: any[];
    labels: string[];
    tooManyValues: boolean;
    loading: boolean;
    progress: Progress = { completed: 0, total: 0 };
    chartThresholds: ChartThreshold[];

    get progressValue() {
        return this.progress.total == 0
            ? 0
            : (this.progress.completed / this.progress.total) * 100;
    }

    constructor(
        title: string,
        type: ChartType,
        signals: CalculatedSignal[],
        chartThresholds: ChartThreshold[],
        private loaderFunction?: (chart: Chart) => void,
    ) {
        this.title = title;
        this.type = type;
        this.calculatedSignals = signals;
        this.chartThresholds = chartThresholds;
        this.filter = new ExpressionNull();
    }

    // Remove any device IDs that aren't in the given array from all signals.
    // Remove any signals that don't have any valid devices.
    filterDevices(deviceIds: number[]) {
        this.calculatedSignals = this.calculatedSignals
            .map((signal) => ({
                ...signal,
                deviceIds: signal.deviceIds.filter((deviceId) =>
                    deviceIds.includes(deviceId),
                ),
            }))
            .filter((signal) => signal.deviceIds.length > 0);
    }

    async load(callback?) {
        if (this.loaderFunction) {
            this.tooManyValues = false;
            this.loading = true;
            await this.loaderFunction(this);
        }
    }

    async setData(
        dataSequences: SignalRawData[],
        labels: any[],
        samplingRate: number,
    ) {
        log.info('START');
        let timestamps = getUniqueTimestamps(dataSequences);
        if (samplingRate != null) {
            timestamps = sampleTimestamps(timestamps, samplingRate);
        }
        const numPoints = timestamps.length;
        const maxNumPoints = 5000;
        if (numPoints > maxNumPoints) {
            log.error('too many points', numPoints);
            this.tooManyValues = true;
            return;
        } else {
            log.info('number of points:', numPoints);
        }
        this.progress = { completed: 0, total: numPoints };
        const snapshots = await convertToSnapshots(
            timestamps,
            dataSequences,
            (completed) => (this.progress.completed = completed),
        );
        this.chartPoints = this.calculatePoints(timestamps, snapshots);
        this.labels = labels;
        this.progress = { completed: 0, total: 0 };
        this.loading = false;
        log.info('END');
    }

    signalName(signal: CalculatedSignal, duplicateIndex: number): string {
        let name = signal.name;
        if (duplicateIndex > 0) {
            name += ' (' + duplicateIndex + ')';
        }
        return name;
    }

    calculatePoints(
        timestamps: number[],
        snapshots: Record<string, SignalsSnapshot>,
    ) {
        // Return the data for each timestamp.
        return timestamps.reduce((pointsArray, timestamp) => {
            // Get the signal values for the expression, for the given device IDs.
            const getValues = (
                expression,
                deviceIds = null,
            ): Map<number, any> => {
                return new Map(
                    expression.signalIds.map((signalId) => {
                        const devicesValues = snapshots[timestamp][signalId];
                        if (!devicesValues) {
                            return [signalId, null];
                        }
                        const deviceId = deviceIds
                            ? deviceIds.find((id) => id in devicesValues)
                            : // Use the first device ID
                              Object.keys(devicesValues)[0];
                        const value = deviceId ? devicesValues[deviceId] : null;
                        return [signalId, value];
                    }),
                );
            };

            let matchesFilter = true;
            if (this.filter && this.filter.kind != ExpressionKind.Null) {
                const expr = this.filter;
                const values = getValues(expr);
                const context = new EvaluationContext(values);
                matchesFilter = expr.evaluate(context);
            }
            if (matchesFilter) {
                // Add the new point.
                return pointsArray.concat([
                    [
                        new Date(Math.round(timestamp * 1000)),
                        ...this.calculatedSignals.map((signal) => {
                            const expr = signal.expression;
                            const values = getValues(expr, signal.deviceIds);
                            const context = new EvaluationContext(values);
                            let output = expr.evaluate(context);
                            // Round numbers to the nearest thousandths
                            if (typeof output == typeof 1) {
                                output = Math.round(output * 100000) / 100000;
                            }
                            return output;
                        }),
                    ],
                ]);
            } else {
                return pointsArray;
            }
        }, []);
    }

    // Use the array of messages to construct a list of the signals used by the
    // calculated signals in this chart.
    allSignals(messages: Array<ChartMessage>) {
        // Create a "white list" of signal IDs.
        const whitelist = this.calculatedSignals.reduce(
            (a, s) => a.concat(s.expression.signalIds),
            [],
        );
        return combineSignals(messages).filter((s) => whitelist.includes(s.id));
    }
}

export function combineSignals(messages: Array<ChartMessage>) {
    return messages.reduce((vars, message) => vars.concat(message.signals), []);
}

function getUniqueTimestamps(dataSequences: SignalRawData[]): number[] {
    // Gather all the unique timestamps.
    const unique = dataSequences
        .map((ds) => ds.values.keys())
        .reduce(union, new Set());
    const timestamps: number[] = Array.from(unique);
    timestamps.sort();
    return timestamps;
}

function sampleTimestamps(
    timestamps: number[],
    samplingRate: number,
): number[] {
    const samples = [];
    let lastTimestamp = 0;
    const delta = 60 / samplingRate;
    for (const timestamp of timestamps) {
        if (timestamp - lastTimestamp >= delta) {
            samples.push(timestamp);
            lastTimestamp = timestamp;
        }
    }
    return samples;
}

// TODO: Move this logic to a web worker instead of using the hacky setTimeout
//       strategy. See #718
function convertToSnapshots(
    timestamps: number[],
    dataSequences: SignalRawData[],
    progressCallback?: (number) => void,
): Promise<Record<string, SignalsSnapshot>> {
    return new Promise((resolve, reject) => {
        const signalIds = dataSequences.map((d) => d.signalId);
        const deviceIds = uniqueArray(dataSequences.map((d) => d.deviceId));
        // Start with empty values for all signals.
        const snapshots: Record<string, SignalsSnapshot> = Object.fromEntries(
            timestamps.map((t) => [
                t,
                Object.fromEntries(
                    signalIds.map((signalId) => [
                        signalId,
                        Object.fromEntries(
                            deviceIds.map((deviceId) => [deviceId, null]),
                        ),
                    ]),
                ),
            ]),
        );
        const data = {};
        const guessIndex = {};
        for (const seq of dataSequences) {
            const key = seq.signalId + '.' + seq.deviceId;
            data[key] = Array.from(seq.values.entries());
            guessIndex[key] = 0;
        }
        // This part of the code is the slow part, so we process it in chunks.
        const timestampChunks = chunkArray(timestamps, 50);
        let progress = 0;
        function doChunk() {
            const chunk = timestampChunks.shift();
            for (const target of chunk) {
                for (const key in data) {
                    const values = data[key];
                    let index = guessIndex[key];
                    // Go forward until we go past the target.
                    while (index < values.length) {
                        if (values[index][0] > target) {
                            break;
                        }
                        index += 1;
                    }
                    if (index < values.length) {
                        // Rewind to a timestamp less than the target.
                        while (index >= 0 && values[index][0] > target) {
                            index -= 1;
                        }
                    }
                    const outOfBounds = index == -1 || index == values.length;
                    const value = outOfBounds ? null : values[index][1];
                    // Remove the values that are no longer needed.
                    values.splice(0, index);
                    const path = [target, ...key.split('.')];
                    setNestedValue(snapshots, path, value);
                }
                progress += 1;
            }
            if (progressCallback) {
                progressCallback(progress);
            }
            if (timestampChunks.length > 0) {
                setTimeout(doChunk, 1);
            } else {
                resolve(snapshots);
            }
        }
        if (timestampChunks.length > 0) {
            doChunk();
        } else {
            resolve(snapshots);
        }
    });
}

export interface EchartData {
    name: string;
    type: string;
    showSymbol: boolean;
    smooth: boolean;
    lineStyle: { normal: { width: number } };
    data: [Date, number][];
}

export interface MinMaxData {
    min: number | string;
    max: number | string;
    mean: number | string;
    selectedVariable: string;
}

export interface PercentageFrequency {
    range: string;
    value: string;
}

export interface FrequencyDistribution {
    selectedVariable: string;
    minValue: number;
    maxValue: number;
    frequency: number;
}

export enum TimeParams {
    Time = 'time',
    Index = 0,
}

export enum DispatchActionType {
    Downplay = 'downplay',
    Highlight = 'highlight',
    ShowTip = 'showTip',
}

export interface MapPoint {
    lat: number;
    lng: number;
    label: string;
    time: Date;
    draggable: boolean;
    isOpenInfo: boolean;
}
export const DEFAULT_MINUTES_VALUE = 5;

export interface Marker {
    lat: number;
    lng: number;
    label: string;
    time: string;
    draggable: boolean;
    isOpenInfo: boolean;
}

export interface TimeDifferenceDetail {
    isEmptyRegionHidden: boolean;
    timeDifference: number;
}
