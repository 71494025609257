import { Widget, WidgetType } from '../widget';
import { WidgetSettingType } from '../widget-settings';

export class SwitchWidget extends Widget {
    type = WidgetType.SwitchWidget;

    constructor(settings?) {
        super(
            [
                {
                    id: 'title',
                    type: WidgetSettingType.Text,
                    name: 'widget.settings.title',
                    previewValue: '',
                    defaultValue: '',
                },
                {
                    id: 'on_text',
                    type: WidgetSettingType.Text,
                    name: 'widget.settings.on_text',
                    previewValue: 'On',
                },
                {
                    id: 'off_text',
                    type: WidgetSettingType.Text,
                    name: 'widget.settings.off_text',
                    previewValue: 'Off',
                },
                {
                    id: 'value',
                    type: WidgetSettingType.Calculated,
                    name: 'widget.settings.initial_value',
                    previewValue: '',
                    defaultValue: '',
                },
                {
                    id: 'callback',
                    type: WidgetSettingType.Calculated,
                    name: 'datasource',
                    previewValue: '',
                    defaultValue: '',
                },
            ],
            settings
        );
    }
}
