import { HttpClient } from '@angular/common/http';
import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { getAccessToken } from '../shared/common';

@Pipe({
    name: 'secure',
})
export class SecurePipe implements PipeTransform {
    constructor(private http: HttpClient) {}

    transform(url: string) {
        return new Observable<string>((observer) => {
            // This is a tiny blank image
            observer.next(
                'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='
            );

            // The next and error callbacks from the observer
            const { next, error } = observer;

            const token = getAccessToken();
            this.http
                .get(url, {
                    responseType: 'blob',
                    headers: { 'x-access-token': token },
                })
                .subscribe((response) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(response);
                    reader.onloadend = function () {
                        observer.next(reader['result'] as string);
                    };
                });

            // TODO: Fix linter error
            // eslint-disable-next-line
            return { unsubscribe() {} };
        });
    }
}
