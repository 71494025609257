import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { MaterialCssVarsService } from 'angular-material-css-vars';
import Color from 'color';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { debounceTime, skip } from 'rxjs/operators';
import { SettingType } from 'src/app/settings/settings.model';
import {
    convertColor,
    convertKeys,
    diffChanges,
    getCustomerId,
    hasRole,
    invertMapping,
    isEmpty,
} from 'src/app/shared/common';
import { log } from 'src/app/shared/log';
import { palette } from 'src/app/shared/palette';
import { environment } from '../../../environments/environment';
import { ApiService } from '../api/api.service';
import { CustomerRole } from '../constants';
import { DateTimeService } from '../date-time.service';

export enum FeatureFlag {
    BikeShop = 'bike-shop',
    NewAddWidgetDialog = 'new-add-widget-dialog',
    TokenHotkey = 'token-hotkey',
}

export function hasFlagAccess(flag: FeatureFlag): boolean {
    const roles = {
        [FeatureFlag.BikeShop]: CustomerRole.Super,
    };
    const role = roles[flag] ?? CustomerRole.Client;
    return hasRole(role);
}

const frontendToBackendIdMapping = {
    'account.first-name': 'firstName',
    'account.language': 'language',
    'account.last-name': 'lastName',
    'account.email': 'email',
    'account.phone-number': 'phone',
    'account.identified-tracking': 'allowIdentifiedTracking',
    'date-time.time-format': 'timeFormat',
    'date-time.date-format': 'dateFormat',
    'date-time.use-local-time': 'useLocalTime',
    'customer.primary-color': 'theme',
    'extras.dark-mode': 'useDarkMode',
    'extras.impersonate': 'canImpersonate',
    'extras.feature-flags': 'featureFlags',
};

@Injectable({ providedIn: 'root' })
export class SettingsService {
    private _backendSettings = {};
    private _currentValues = {};
    private _allSettings = [];
    values$ = new BehaviorSubject(this._currentValues);
    loaded$ = new ReplaySubject(1);
    isSaved$ = new BehaviorSubject<boolean>(true);

    get isLoaded() {
        return Object.keys(this._currentValues).length > 0;
    }

    get logoUrl() {
        if (this.get('customer.logo') == undefined) {
            return environment.apiUrl + '/images/SZ-logo.png';
        }
        return environment.apiUrl + this.get('customer.logo');
    }

    get allSettings() {
        return this._allSettings;
    }

    constructor(
        private materialCssVarsService: MaterialCssVarsService,
        private translate: TranslateService,
        private titleService: Title,
        private api: ApiService,
        private dateTime: DateTimeService,
    ) {}

    /**
     * Clear all the settings, and clear the loaded status.
     */
    clear() {
        this._currentValues = {};
        this.loaded$ = new ReplaySubject(1);
    }

    /**
     * Initialize the settings with the current values.
     */
    async init(settings) {
        this._currentValues = {};
        this._allSettings = getAllSettings(getCustomerId());
        this.allSettings.forEach((setting) => {
            if ('default' in setting) {
                this._currentValues[setting.id] = setting.default;
            }
        });
        await this.load(settings);
        this.loaded$.next(settings);
        this.values$
            .pipe(skip(2), debounceTime(3000))
            .subscribe(() => this.save());
        this.values$.pipe(skip(1)).subscribe(() => this.isSaved$.next(false));
    }

    /**
     * Get the value of a specific setting.
     */
    get(settingId: string) {
        return this._currentValues[settingId];
    }

    /**
     * Set the value of a specific setting.
     */
    async set(settingId: string, value) {
        // This keeps updatedSettings from being called needlessly.
        if (this.get(settingId) == value) {
            return;
        }
        await this.update({ [settingId]: value });
    }

    /**
     * Update setting values.
     */
    async update(settings) {
        for (const id of Object.keys(settings)) {
            if (id in this._currentValues) {
                let value = settings[id];
                // Handle side effects.
                switch (id) {
                    case 'account.language':
                        this.translate.use(value);
                        if (localStorage.getItem('language') != value) {
                            if (localStorage.getItem('language')) {
                                this.titleService.setTitle(
                                    await this.translate
                                        .get('setting.plural')
                                        .toPromise(),
                                );
                            }
                            localStorage.setItem('language', value);
                        }
                        break;
                    case 'customer.primary-color': {
                        value = convertColor(value);
                        this.materialCssVarsService.setPrimaryColor(value);
                        this.updateDaisyUiColors({ primaryColor: value });
                        break;
                    }
                    case 'customer.icon':
                        this.updateFavicon();
                        break;
                    case 'extras.dark-mode':
                        this.materialCssVarsService.setDarkTheme(value);
                        this.updateDaisyUiColors({ isDarkMode: value });
                        break;
                    case 'date-time.time-format':
                        this.dateTime.timeFormat = value;
                        break;
                    case 'date-time.date-format':
                        this.dateTime.dateFormat = value;
                        break;
                    case 'date-time.use-local-time':
                        this.dateTime.isUtcTime = !value;
                        break;
                }
                if (value == undefined) {
                    value = this.findSetting(id).default;
                }
                // Set the value.
                this._currentValues[id] = value;
            }
        }
        this.values$.next(this._currentValues);
    }

    /**
     * Load the settings from the backend to the frontend.
     */
    async load(backendSettings) {
        // Keep a record of the current (un-changed) backend settings.
        this._backendSettings = backendSettings;
        // Convert backend settings to frontend settings.
        const frontendSettings = convertKeys(
            this._backendSettings,
            invertMapping(frontendToBackendIdMapping),
        );
        if (backendSettings.customerId) {
            frontendSettings['customer.logo'] =
                '/api/v2/customers/' + backendSettings.customerId + '/logo';
            frontendSettings['customer.icon'] =
                '/api/v2/customers/' + backendSettings.customerId + '/icon';
        }
        if (backendSettings.canImpersonate) {
            this._allSettings.push({
                id: 'extras.impersonate',
                title: '',
                description: '',
                default: '',
            });
        }
        await this.update(frontendSettings);
    }

    /**
     * Save the settings from the backend to the frontend.
     */
    async save() {
        // Convert from frontend settings to backend settings.
        const newBackendSettings = convertKeys(
            this._currentValues,
            frontendToBackendIdMapping,
        );
        // Calculate the changes in the settings.
        const changes = diffChanges(this._backendSettings, newBackendSettings);
        // Send the changes to the backend.
        try {
            if (!isEmpty(changes)) {
                await this.api.settings.update(changes);
                this._backendSettings = newBackendSettings;
            }
            this.isSaved$.next(true);
        } catch (error) {
            log.error(error);
        }
    }

    /**
     * Update the browser favicon.
     */
    updateFavicon() {
        // update favicon
        const src =
            environment.apiUrl +
            '/icons/' +
            getCustomerId() +
            '?=' +
            Math.random();
        const link = document.createElement('link'),
            oldLink = document.getElementById('favicon');
        link.id = 'favicon';
        link.rel = 'shortcut icon';
        link.href = src;
        if (oldLink) document.head.removeChild(oldLink);
        document.head.appendChild(link);
    }

    /**
     * Find the setting with the given ID.
     */
    findSetting(settingId: string) {
        for (const setting of this.allSettings) {
            if (setting.id == settingId) {
                return setting;
            }
        }
        return null;
    }

    useFeature(flag: string) {
        let features = [];
        if (this.get('extras.feature-flags')) {
            features = this.get('extras.feature-flags').split(' ');
        }
        return features.includes(flag) && hasFlagAccess(flag as FeatureFlag);
    }

    /**
     * Update the CSS vars used to theme Daisy UI components.
     */
    updateDaisyUiColors({
        primaryColor,
        isDarkMode,
    }: {
        primaryColor?: string;
        isDarkMode?: boolean;
    }) {
        primaryColor ??= this._currentValues['customer.primary-color'];
        const color = Color(primaryColor);
        // TODO: in the future, use dark mode value to set the base-100 color.
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        isDarkMode ??= this._currentValues['extras.dark-mode'];
        const colors = {
            p: color,
            s: color.negate(),
            a: color.isDark()
                ? Color(primaryColor).lightness(80)
                : Color(primaryColor).lightness(20),
            n: Color('#262626'),
        };
        const vars: Record<string, string> = {};
        for (const [prefix, color] of Object.entries(colors)) {
            vars[prefix] = colorHslValues(color);
            vars[prefix + 'f'] = colorHslValues(color.darken(0.07));
            if (color.isDark()) {
                vars[prefix + 'c'] = colorHslValues(
                    color.mix(Color('white'), 0.8),
                );
            } else {
                vars[prefix + 'c'] = colorHslValues(
                    color.mix(Color('black'), 0.8),
                );
            }
        }
        if (isDarkMode) {
            vars['b1'] = colorHslValues(Color('#171717'));
            vars['b2'] = colorHslValues(Color('#0a0a0a'));
            vars['b3'] = colorHslValues(Color('#000000'));
            vars['bc'] = colorHslValues(Color('#ffffff'));
        } else {
            vars['b1'] = colorHslValues(Color('#ffffff'));
            vars['b2'] = colorHslValues(Color('#f5f5f5'));
            vars['b3'] = colorHslValues(Color('#e5e5e5'));
            vars['bc'] = colorHslValues(Color('#171717'));
        }
        // TODO: implement info, success, warning, error colors
        for (const [name, value] of Object.entries(vars)) {
            document.documentElement.style.setProperty(`--${name}`, value);
        }
    }
}

function colorHslValues(color: Color) {
    const [h, s, l] = color.hsl().array();
    return `${h} ${s}% ${l}%`;
}

function getAllSettings(customerId: number) {
    return [
        {
            id: 'account.first-name',
            title: 'account.first_name',
            description: 'account.first_name.description',
            type: SettingType.Text,
            default: '',
        },
        {
            id: 'account.last-name',
            title: 'account.last_name',
            description: 'account.last_name.description',
            type: SettingType.Text,
            default: '',
        },
        {
            id: 'account.email',
            title: 'account.email_address',
            description: 'account.email_address.description',
            type: SettingType.Text,
            disabled: true,
            default: '',
            // TODO: we need to add validation if we re-enable updating user emails in the future
        },
        {
            id: 'account.phone-number',
            title: 'account.phone_number',
            description: 'account.phone_number.description',
            type: SettingType.Text,
            default: '',
            config: {
                pattern:
                    /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/,
                errorMessage: 'account.phone_number.invalid',
            },
        },
        {
            id: 'account.language',
            title: 'account.language',
            description: 'account.language.description',
            type: SettingType.Select,
            default: 'en',
            config: {
                options: [
                    { name: 'English', value: 'en' },
                    { name: 'Русский', value: 'ru' },
                    { name: 'Español', value: 'es' },
                ],
            },
        },
        {
            id: 'account.identified-tracking',
            title: 'settings.identified_tracking',
            description: 'settings.identified_tracking.description',
            type: SettingType.Toggle,
            default: null,
            config: {
                onLabel: 'settings.identified_tracking.personalized',
                offLabel: 'settings.identified_tracking.anonymous',
            },
        },
        {
            id: 'account.reset-password',
            title: '',
            description: '',
            default: '',
        },
        {
            id: 'date-time.preview',
            title: '',
            description: '',
            default: '',
        },
        {
            id: 'date-time.time-format',
            title: 'settings.date_time.time_format',
            description: 'settings.date_time.time_format.description',
            type: SettingType.Select,
            default: 'hh:mm:ss a',
            config: {
                options: [
                    { name: 'hh:mm:ss a', value: 'hh:mm:ss a' },
                    { name: 'HH:mm:ss', value: 'HH:mm:ss' },
                ],
            },
        },
        {
            id: 'date-time.date-format',
            title: 'settings.date_time.date_format',
            description: 'settings.date_time.date_format.description',
            type: SettingType.Select,
            default: 'MM/dd/yyyy',
            config: {
                options: [
                    { name: 'MM/dd/yyyy', value: 'MM/dd/yyyy' },
                    { name: 'dd-MM-yyyy', value: 'dd-MM-yyyy' },
                    { name: 'yyyy-MM-dd', value: 'yyyy-MM-dd' },
                    { name: 'yyyy.MM.dd', value: 'yyyy.MM.dd' },
                ],
            },
        },
        {
            id: 'date-time.use-local-time',
            title: 'settings.customer.local_time',
            description: 'settings.customer.local_time.description',
            type: SettingType.Toggle,
            default: false,
            config: {
                onLabel: 'settings.customer.local_time',
                offLabel: 'UTC',
            },
        },
        {
            id: 'customer.primary-color',
            title: 'settings.customer.primary_color',
            description: 'settings.customer.primary_color.description',
            type: SettingType.Color,
            default: palette.Default,
        },
        {
            id: 'customer.logo',
            title: 'settings.customer.logo',
            description: 'settings.customer.logo.description',
            type: SettingType.Image,
            default: '/images/SZ-logo.png',
        },
        {
            id: 'customer.icon',
            title: 'settings.customer.icon',
            description: 'settings.customer.icon.description',
            type: SettingType.Image,
            default: '/images/favicon.ico',
            config: { faviconCustomerId: customerId },
        },
        {
            id: 'extras.dark-mode',
            title: 'settings.dark_mode',
            description: 'settings.dark_mode.description',
            type: SettingType.Toggle,
            default: false,
            config: {
                onLabel: 'settings.dark_mode',
                offLabel: 'settings.light_mode',
            },
        },
        {
            id: 'extras.feature-flags',
            title: 'settings.feature_flags',
            description: 'settings.feature_flags.description',
            default: '',
        },
    ];
}
