import { HttpParams } from '@angular/common/http';
import { ApiService } from './api.service';
import { exportDateString } from 'src/app/shared/common';

type Params = Record<string, string | number | boolean | Date>;

export class BasicRouteApi {
    get path(): string {
        throw Error('The child class must implement this field');
    }

    get basePath(): string {
        return this.api.baseUrl + '/' + this.path;
    }

    constructor(protected api: ApiService) {}

    async retrieve<T>(relativePath: string, params?: Params) {
        return (await this.api.client
            .get(this.basePath + '/' + relativePath, {
                params: this.buildParams(params),
            })
            .toPromise()) as T;
    }

    async retrieveData(relativePath: string, params?: Params): Promise<string> {
        return (
            await this.api.client
                .get(this.basePath + '/' + relativePath, {
                    params: this.buildParams(params),
                    responseType: 'blob',
                })
                .toPromise()
        ).text();
    }

    async send<Data>(relativePath: string, data: Data) {
        return await this.api.client
            .post(this.basePath + '/' + relativePath, data)
            .toPromise();
    }

    async patch<Updates>(relativePath: string, data: Updates) {
        return await this.api.client
            .patch(this.basePath + '/' + relativePath, data)
            .toPromise();
    }

    async put<Data>(relativePath: string, data: Data) {
        return await this.api.client
            .patch(this.basePath + '/' + relativePath, data)
            .toPromise();
    }

    buildParams(params?: Params) {
        if (params == undefined) {
            params = {};
        }
        return new HttpParams({
            fromObject: Object.fromEntries(
                Object.entries(params).map(([key, value]: [string, any]) => {
                    if (typeof value == 'boolean') {
                        // Convert from boolean to integer, so that the backend can
                        // process it.
                        // TODO: correctly handle booleans in the backend.
                        return [key, +value];
                    } else if (value instanceof Date) {
                        // Convert dates to strings.
                        value = exportDateString(value);
                    }
                    return [key, value];
                })
            ),
        });
    }
}
