export interface CreateDevice {
    customerId: number;
    iccid: string;
    identifier: string;
    modelId: number;
    oemId: number;
    serialNumber: string;
    simStatus: string;
    statusId: number;
    tags: Record<string, string>;
    userIdentifier: string;
    deviceTypeId?: number;
    ratePlanId?: number;
}

export type Device = CreateDevice & {
    allowTelematics: boolean;
    clientName: string;
    createdDate: string;
    customerRole: number;
    dealerId: number;
    dealerName: string;
    iccid: string;
    id: number;
    lastLatitude: number;
    lastLongitude: number;
    lastOnline: Date;
    lastUpdated: string;
    name: string;
    oemName: string;
    primarySoftwareVersion?: string;
    productImage: string;
    productType: string;
    softwareVersions: Record<string, string>;
    numAlerts?: number;
};

export type OnlineCategory = 'online' | 'recently-online' | 'offline';

// TODO: use a better name
export type LiveMapDevice = Device & {
    lastOnlineStr: string;
    setTimeoutRef?: number;
    symbol: google.maps.Symbol;
    onlineCategory: OnlineCategory;
};

export interface RatePlan {
    id: number;
    name: string;
    monthlyRate: number;
    poolSize: number;
}

export type SimStatus = {
    usage: string;
    status: Status;
    inSession: boolean;
};

export enum Status {
    Activated = 'ACTIVATED',
    ActivationReady = 'ACTIVATION_READY',
    Deactivated = 'DEACTIVATED',
    NotAvailable = 'NOT_AVAILABLE',
    TestReady = 'TEST_READY',
}

export const searchFields = [
    'id',
    'clientName',
    'dealerName',
    'identifier',
    'name',
    'oemName',
    'iccid',
    'serialNumber',
];

export function getSimStatusName(status: string) {
    switch (status) {
        case Status.Activated:
            return 'device.sim.activated';
        case Status.ActivationReady:
            return 'device.sim.activation_ready';
        case Status.Deactivated:
            return 'device.sim.deactivated';
        case Status.NotAvailable:
            return 'device.sim.not_available';
        case Status.TestReady:
            return 'device.sim.test_ready';
        default:
            return 'common.unknown';
    }
}

export function getStatusColor(status: Status) {
    switch (status) {
        case Status.Activated:
            return '#2BAA1D';
        case Status.Deactivated:
            return 'red';
        case Status.ActivationReady:
        case Status.TestReady:
            return '#CC8500';
        case Status.NotAvailable:
        default:
            return 'gray';
    }
}

export function onlineCategoryColor(sectionId: OnlineCategory) {
    switch (sectionId) {
        case 'online':
            return 'green';
        case 'recently-online':
            return 'gray';
        case 'offline':
            return 'red';
    }
}

export function hasValidLocation(d: Device) {
    const lat = d.lastLatitude ?? 91;
    const lon = d.lastLongitude ?? 181;
    return lat >= -90 && lat <= 90 && lon >= -180 && lon <= 180;
}

export const getTypeName = (type: number) => {
    switch (type) {
        case 1:
            return 'device.type.display';
        case 2:
            return 'device.type.telematics';
        case 3:
            return 'device.type.connected_logger';
        default:
            return 'common.unknown';
    }
};
