import { getNestedValue, isDefined } from 'src/app/shared/common';
import { log } from 'src/app/shared/log';
import { isPath, parseSegments } from 'src/app/shared/topic-utils';
import { v4 as uuidv4 } from 'uuid';
import { MonitorGridWidgetDialog } from '../components/widget-setup-dialogs/monitor-grid-widget-dialog/monitor-grid-widget-dialog.component';
import { PointerWidgetDialog } from '../components/widget-setup-dialogs/pointer-widget-dialog/pointer-widget-dialog.component';
import { SingleMonitorWidgetDialog } from '../components/widget-setup-dialogs/single-monitor-widget-dialog/single-monitor-widget-dialog.component';
import { IWidgetTopicParse } from './common.model';
import { Widget, WidgetType } from './widget';
import { BarGraphWidget } from './widgets/bar-graph';
import { ButtonWidget } from './widgets/button';
import { CustomWidget } from './widgets/custom';
import { DonutWidget } from './widgets/donut-chart';
import { GaugeWidget } from './widgets/gauge';
import { HexWidget } from './widgets/hex';
import { IndicatorWidget } from './widgets/indicator';
import { InteractiveIndicatorWidget } from './widgets/interactive-indicator';
import { LineGraphWidget } from './widgets/line-graph';
import { MapWidget } from './widgets/map';
import { NumberSenderWidget } from './widgets/number-sender';
import { PictureWidget } from './widgets/picture';
import { PieWidget } from './widgets/pie-chart';
import { SwitchWidget } from './widgets/switch';
import { TextWidget } from './widgets/text';
import { TextSenderWidget } from './widgets/text-sender';
import { TwoActionWidget } from './widgets/two-action';
import { LinkWidget } from './widgets/link';
import { RebootCommandWidget } from './widgets/reboot-command';

// TODO: split this file into smaller files, delete the original file.

export class WidgetPointer extends Widget {
    type = WidgetType.POINTER_WIDGET;
    componentDialog = PointerWidgetDialog;

    value_text = {
        datasourceName: '', // Name
        datasourceType: '', // mrs_mqtt ...
        widgetValueType: '', // Energy
        topic: '', // /mon/bus_id/can_id,
    };

    direction = {
        datasourceName: '', // Name
        datasourceType: '', // mrs_mqtt ...
        widgetValueType: '', // Energy
        topic: '', // /mon/bus_id/can_id,
    };

    settings = {
        direction: '',
        units: '',
        value_text: '',
        animation: true,
        compass: false,
        colorPlate: '#00a6ae',
        colorText: '#f5f5f5',
    };

    calcProps = {
        direction: {} as IWidgetTopicParse,
        value_text: {} as IWidgetTopicParse,
    };

    constructor(settings?) {
        super();
        if (settings) {
            Object.assign(this.settings, settings);
            this.init();
        }
    }

    init() {
        this.setWidgetValueData();
    }

    private setWidgetValueData(): [] | any {
        const valueStr = this.settings.direction;

        if (this.settings.direction) {
            const matches = this.initialDatasourceParse(valueStr);

            if (matches) {
                this.direction.datasourceName = matches[0];
                this.direction.topic = matches[1];
                this.direction.widgetValueType = this.getWidgetValue(matches);
            }
        }

        if (this.settings.value_text) {
            const matches = this.initialDatasourceParse(
                this.settings.value_text,
            );

            if (matches) {
                this.value_text.datasourceName = matches[0];
                this.value_text.topic = matches[1];
                this.value_text.widgetValueType = this.getWidgetValue(matches);
            }
        }
    }

    runExecuteDirection(datasources) {
        const value = this.settings.direction;
        return execCode(value, datasources);
    }

    runExecuteValueText(datasources) {
        const value = this.settings.value_text;
        return execCode(value, datasources);
    }
}

export class WidgetMonitorGrid extends Widget {
    type = WidgetType.MONITOR_GRID_WIDGET;
    componentDialog = MonitorGridWidgetDialog;

    settings = {
        mg_bg1: '',
        mg_bg2: '',
        mg_bg3: '',
        mg_bg4: '',
        mg_bg5: '',
        mg_bg6: '',
        mg_data1: '',
        mg_data2: '',
        mg_data3: '',
        mg_data4: '',
        mg_data5: '',
        mg_data6: '',
        mg_title1: '',
        mg_title2: '',
        mg_title3: '',
        mg_title4: '',
        mg_title5: '',
        mg_title6: '',
        mg_unit1: '',
        mg_unit2: '',
        mg_unit3: '',
        mg_unit4: '',
        mg_unit5: '',
        mg_unit6: '',
    };

    dataInfo = {};

    calcProps = {
        mg_bg1: {} as IWidgetTopicParse,
        mg_bg2: {} as IWidgetTopicParse,
        mg_bg3: {} as IWidgetTopicParse,
        mg_bg4: {} as IWidgetTopicParse,
        mg_bg5: {} as IWidgetTopicParse,
        mg_bg6: {} as IWidgetTopicParse,
        mg_data1: {} as IWidgetTopicParse,
        mg_data2: {} as IWidgetTopicParse,
        mg_data3: {} as IWidgetTopicParse,
        mg_data4: {} as IWidgetTopicParse,
        mg_data5: {} as IWidgetTopicParse,
        mg_data6: {} as IWidgetTopicParse,
    };

    constructor(settings?) {
        super();
        if (settings) {
            Object.assign(this.settings, settings);
            this.init();
        }
    }

    init() {
        this.setWidgetValueData();
    }

    private setWidgetValueData() {
        const withSource = {
            mg_bg1: this.settings.mg_bg1,
            mg_bg2: this.settings.mg_bg2,
            mg_bg3: this.settings.mg_bg3,
            mg_bg4: this.settings.mg_bg4,
            mg_bg5: this.settings.mg_bg5,
            mg_bg6: this.settings.mg_bg6,
            mg_data1: this.settings.mg_data1,
            mg_data2: this.settings.mg_data2,
            mg_data3: this.settings.mg_data3,
            mg_data4: this.settings.mg_data4,
            mg_data5: this.settings.mg_data5,
            mg_data6: this.settings.mg_data6,
        };

        for (const key in withSource) {
            const str = withSource[key];
            if (str) {
                const matches = this.initialDatasourceParse(str);

                if (!matches) {
                    continue;
                }
                this.dataInfo[key] = {
                    datasourceName: matches[0],
                    topic: matches[1],
                    widgetValueType: this.getWidgetValue(matches),
                };
            }
        }
    }

    runExecuteClientCode(datasources) {
        const withSource = {
            mg_bg1: this.settings.mg_bg1,
            mg_bg2: this.settings.mg_bg2,
            mg_bg3: this.settings.mg_bg3,
            mg_bg4: this.settings.mg_bg4,
            mg_bg5: this.settings.mg_bg5,
            mg_bg6: this.settings.mg_bg6,
            mg_data1: this.settings.mg_data1,
            mg_data2: this.settings.mg_data2,
            mg_data3: this.settings.mg_data3,
            mg_data4: this.settings.mg_data4,
            mg_data5: this.settings.mg_data5,
            mg_data6: this.settings.mg_data6,
        };

        const resp = {};
        for (const prop in withSource) {
            resp[prop] = execCode(withSource[prop], datasources);
        }

        return resp;
    }
}

export class WidgetSingleMonitor extends Widget {
    type = WidgetType.SINGLE_MONITOR_WIDGET;
    componentDialog = SingleMonitorWidgetDialog;

    datasourceName; // Name
    datasourceType; // mrs_mqtt ...
    widgetValueType; // Energy
    topic; // /mon/bus_id/can_id,

    settings = {
        sm_Data: '',
        sm_Title: '',
        sm_Unit: '',
    };

    calcProps = {
        sm_Data: {} as IWidgetTopicParse,
    };

    constructor(settings?) {
        super();
        if (settings) {
            Object.assign(this.settings, settings);
            this.init();
        }
    }

    init() {
        this.setWidgetValueData();
    }

    private setWidgetValueData(): [] | any {
        const valueStr = this.settings.sm_Data;

        if (this.settings.sm_Data) {
            const matches = this.initialDatasourceParse(valueStr);

            if (!matches) {
                log.info('WidgetGauge parsed error', this.settings);
                return;
            }

            this.datasourceName = matches[0];
            this.topic = matches[1];
            this.widgetValueType = this.getWidgetValue(matches);
        }
    }

    runExecuteClientCode(datasources) {
        const value = this.settings.sm_Data;
        return execCode(value, datasources);
    }
}

export class WidgetFactory {
    /* Create or rebuild a widget. */
    static createWidget(args: {
        type;
        uuid?;
        settings?;
        customWidgetId?;
        height?;
    }): Widget {
        const { type, settings, customWidgetId, height } = args;
        let { uuid } = args;
        if (!uuid) {
            uuid = uuidv4();
        }
        const instance = this._createInstance(type, settings, customWidgetId);
        // If there is a saved height, load it.
        if (height) {
            instance.height = height;
        }
        instance.uuid = uuid;
        return instance;
    }

    static _createInstance(type, settings, customWidgetId): Widget {
        switch (type) {
            case WidgetType.BarGraph:
                return new BarGraphWidget(settings);
            case WidgetType.GaugeWidget:
                return new GaugeWidget(settings);
            case WidgetType.ButtonWidget:
                return new ButtonWidget(settings);
            case WidgetType.SwitchWidget:
                return new SwitchWidget(settings);
            case WidgetType.NumberSenderWidget:
                return new NumberSenderWidget(settings);
            case WidgetType.INDICATOR:
                return new IndicatorWidget(settings);
            case WidgetType.LineGraph:
                return new LineGraphWidget(settings);
            case WidgetType.Text:
                return new TextWidget(settings);
            case WidgetType.PictureWidget:
                return new PictureWidget(settings);
            case WidgetType.MapWidget:
                return new MapWidget(settings);
            case WidgetType.POINTER_WIDGET:
                return new WidgetPointer(settings);
            case WidgetType.InteractiveIndicatorWidget:
                return new InteractiveIndicatorWidget(settings);
            case WidgetType.TextSenderWidget:
                return new TextSenderWidget(settings);
            case WidgetType.MONITOR_GRID_WIDGET:
                return new WidgetMonitorGrid(settings);
            case WidgetType.SINGLE_MONITOR_WIDGET:
                return new WidgetSingleMonitor(settings);
            case WidgetType.TwoActionWidget:
                return new TwoActionWidget(settings);
            case WidgetType.DonutWidget:
                return new DonutWidget(settings);
            case WidgetType.PieWidget:
                return new PieWidget(settings);
            case WidgetType.HexWidget:
                return new HexWidget(settings);
            case WidgetType.LinkWidget:
                return new LinkWidget(settings);
            case WidgetType.RebootCommandWidget:
                return new RebootCommandWidget(settings);
            default:
                if (!isDefined(customWidgetId)) {
                    throw Error(`Invalid widget type: ${type}`);
                }
                return new CustomWidget(settings, customWidgetId);
        }
    }
}

/**
 * @param value - is that what user input
 * @param datasources - object which contain all mqtt data
 */
let lastTimestamp = 0;
let countInSecond = 0;
const segmentsCache: Record<string, string[]> = {};
export function execCode(value: string, datasources: object) {
    if (!value) {
        return '';
    }
    // Add the value to the cache if it's not there already
    if (!isDefined(segmentsCache[value])) {
        if (isPath(value)) {
            segmentsCache[value] = parseSegments(value);
        } else {
            segmentsCache[value] = [];
        }
    }
    // Get the value directly to avoid eval
    if (segmentsCache[value].length > 0) {
        return getNestedValue(datasources, segmentsCache[value]);
    }
    const now = +new Date();
    if (now - lastTimestamp > 1000) {
        log.info('eval in last second:', countInSecond);
        lastTimestamp = now;
        countInSecond = 0;
    }
    countInSecond += 1;
    const datasourcesString = `datasources = ${JSON.stringify(datasources)};\n`;
    const code = datasourcesString + value;
    let result;
    // in value could be literal, expression with 'return' or without;
    try {
        result = (0, eval)(code); // if no 'return' word
    } catch {
        try {
            result = (0, eval)(`(() => {${code}})()`); // if with 'return' word
        } catch {
            try {
                result = (0, eval)(`(${code})`);
            } catch {
                result = null;
            }
        }
    }

    if (
        (typeof result === 'object' || typeof result === 'function') &&
        result !== null
    ) {
        result = Object.keys(result).length ? result : null;
    }
    return result;
}
