import { CustomerRole } from '../services/constants';
import { Organization } from '../shared/models/customer';
import { Model } from '../shared/models/model';

export enum SettingType {
    Color,
    Image,
    Model,
    Organization,
    Select,
    Text,
    Toggle,
}

export interface Setting {
    id: string;
    title: string;
    description?: string;
    disabled?: boolean;
    type?: SettingType;
    default?: boolean | number | string;
    config?:
        | ToggleConfig
        | SelectConfig
        | ImageConfig
        | TextConfig
        | OrganizationConfig
        | ModelConfig;
}

export interface ToggleConfig {
    onLabel: string;
    offLabel: string;
}

export interface SelectOption {
    name: string;
    value: string | number;
    icon?: string;
}

export interface SelectConfig {
    options: SelectOption[];
    isRequired?: boolean;
    isMultiple?: boolean;
}

export interface ImageConfig {
    faviconCustomerId?: number;
}

export interface TextConfig {
    errorMessage?: string;
    minimum?: number;
    pattern?: RegExp;
    showError?: boolean;
    type?: string;
}

export interface OrganizationConfig {
    onlyRoles?: CustomerRole[];
    organizations?: Organization[];
}

export interface ModelConfig {
    models?: Model[];
}
