import {
    Component,
    EventEmitter,
    HostBinding,
    Input,
    Output,
    forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SelectOption } from 'src/app/settings/settings.model';

@Component({
    selector: 'sz-select',
    templateUrl: './select.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SelectComponent),
            multi: true,
        },
    ],
})
export class SelectComponent implements ControlValueAccessor {
    private _value = null;

    @Input({ required: true }) options: SelectOption[] = [];
    @Input() isRequired = false;
    @Input() isMultiple = false;
    @Output() valueChange = new EventEmitter();

    @Input() disabled = false;
    @HostBinding('style.opacity')
    get opacity() {
        return this.disabled ? 0.25 : 1;
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onChange = (value) => {};
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onTouched = () => {};

    @Input() set value(v) {
        if (!this.disabled) {
            this.writeValue(v);
        }
    }
    get value() {
        return this._value;
    }

    writeValue(value) {
        this._value = value;
        this.onChange(this.value);
        if (value != null) {
            this.valueChange.emit(value);
        }
    }

    registerOnChange(fn: (value) => void) {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void) {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean) {
        this.disabled = isDisabled;
    }
}
