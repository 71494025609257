import { isDefined } from 'src/app/shared/common';
import { log } from 'src/app/shared/log';
import { PaneConfig } from 'src/app/shared/models/dashboard';
import { v4 as uuidv4 } from 'uuid';
import { Widget } from './widget';
import { WidgetFactory } from './widget.model';

export class Pane {
    id: string;
    title = '';
    widgets: Widget[] = [];
    is_minimize = false;

    constructor(data?: Partial<PaneConfig>) {
        this.updateSettings(data);
        if (!this.id) {
            this.id = uuidv4();
        }
        this.widgets = [];
        if (data && data.widgets) {
            this.widgets = data.widgets
                .map((w) => {
                    if (w.customWidgetId == undefined) {
                        // The `customWidgetId` field used to be named `id`.
                        w.customWidgetId = w.id;
                    }
                    try {
                        return WidgetFactory.createWidget(w);
                    } catch (e) {
                        return undefined;
                    }
                })
                .filter((w) => isDefined(w));
        }
    }

    addWidget(widget: Widget) {
        this.is_minimize = false;
        if (!widget) {
            log.error('addWidget ', widget);
            return;
        }
        this.widgets.push(widget);
    }

    getWidgetIndex(widget) {
        return this.widgets.findIndex((w) => w === widget);
    }

    moveWidget(widget, delta: -1 | 1) {
        const index = this.getWidgetIndex(widget);
        const newIndex = index + delta;
        if (newIndex >= 0 && newIndex <= this.widgets.length - 1) {
            this.widgets[index] = this.widgets[newIndex];
            this.widgets[newIndex] = widget;
        }
    }

    deleteWidget(widget: Widget) {
        if (!widget) {
            log.error('can not delete missing widget: ', widget);
            return;
        }
        this.widgets = this.widgets.filter((w) => w !== widget);
    }

    updateSettings(config: Partial<PaneConfig>) {
        if (config == null) {
            return;
        }
        for (const key of ['id', 'title', 'is_minimize']) {
            if (key in config) {
                this[key] = config[key];
            }
        }
    }

    build(): PaneConfig {
        return {
            id: this.id,
            title: this.title,
            is_minimize: this.is_minimize,
            widgets: this.widgets
                ? this.widgets.filter((w) => !!w).map((w) => w.serialized)
                : null,
        };
    }
}
