import { environment } from 'src/environments/environment';

export const urlBaseV2 = environment.apiUrl + '/api/v2';

export const enum CustomerRole {
    Super = 0,
    Administrator = 1,
    OEM = 2,
    Dealer = 3,
    Client = 4,
    Mobile = 5,
    Device = 6,
}

export const customerRouteRoles = {
    administrators: CustomerRole.Administrator,
    oems: CustomerRole.OEM,
    dealers: CustomerRole.Dealer,
    clients: CustomerRole.Client,
};

export const customerRoleArray = [
    CustomerRole.Super,
    CustomerRole.Administrator,
    CustomerRole.OEM,
    CustomerRole.Dealer,
    CustomerRole.Client,
];

export function getCustomerRoleName(role: CustomerRole): string {
    switch (role) {
        case CustomerRole.Super:
            return 'customer.role.super';
        case CustomerRole.Administrator:
            return 'customer.role.administrator';
        case CustomerRole.OEM:
            return 'customer.role.oem';
        case CustomerRole.Dealer:
            return 'customer.role.dealer';
        case CustomerRole.Client:
            return 'customer.role.client';
        default:
            return '';
    }
}

export const customerRoutes = Object.keys(customerRouteRoles);

export enum UserRole {
    ReadWrite = 1,
    ReadOnly = 2,
    Disabled = 3,
}

export function getUserRoleName(role: UserRole): string {
    switch (role) {
        case UserRole.ReadWrite:
            return 'user.permissions.read_write';
        case UserRole.ReadOnly:
            return 'user.permissions.read_only';
        case UserRole.Disabled:
            return 'user.permissions.disabled';
    }
}

export const enum MqttPermission {
    ReadWrite = 1,
    ReadOnly = 2,
    Disabled = 3,
}

export enum DeviceStatus {
    Active = 1,
    // Online = 2, // This isn't used anymore.
    Disabled = 3,
}

export function getDeviceStatusName(status: DeviceStatus) {
    switch (status) {
        case DeviceStatus.Active:
            return 'status.active';
        case DeviceStatus.Disabled:
            return 'status.disabled';
    }
}

export const appConstants = Object.freeze({
    role_super: 0,
    role_administrator: 1,
    role_oem_user: 2,
    role_dealer_user: 3,
    role_client_user: 4,

    user_read_write: 1,
    user_read_only: 2,

    user_locked: 1,
    user_unlocked: 0,

    device_active: 1,
    device_online: 2,
    device_disabled: 3,
});

export const DEFAULT_PRECISION_VALUE = 5;
export const SNACKBAR_SHORT_DURATION = 1000;
export const DEFAULT_TIME_DIFFERENCE = 5;
export const MINIMUM_VALUE = 0.00001;

/**
 * Will round off number to the defined number of decimal places
 * @param numValue
 * @param decimalPlaces
 * @returns
 */
export function roundNumber(numValue: number, decimalPlaces = 5): number {
    const decimalValue: number = parseInt('1' + '0'.repeat(decimalPlaces));
    return Math.round(numValue * decimalValue) / decimalValue;
}
