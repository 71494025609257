import { Widget, WidgetType } from '../widget';
import { WidgetSettingType } from '../widget-settings';

export class PictureWidget extends Widget {
    type = WidgetType.PictureWidget;

    constructor(settings?) {
        super(
            [
                {
                    id: 'src',
                    type: WidgetSettingType.Text,
                    name: 'widget.settings.image_url',
                    previewValue: 'assets/img/SZ-logo_black.png',
                },
                {
                    id: 'refresh',
                    type: WidgetSettingType.Number,
                    name: 'widget.settings.refresh',
                    defaultValue: 600,
                    previewValue: 600,
                    config: { suffix: 'unit.seconds' },
                },
            ],
            settings
        );
    }
}
