import {
    Alert,
    AlertsQueryParams,
    CopyAlert,
    CreateAlert,
} from 'src/app/shared/models/alerts';
import { AlertPreview } from 'src/app/shared/models/preview';
import { ApiService } from './api.service';
import {
    CachedApi,
    RawAccessAdapter,
    createRawAccess,
    ensureLoaded,
    waitForItem,
} from './cached-api';
import { CreateResult, TopLevelRouteApi } from './top-level-route-api';

export class RawAlertsApi extends TopLevelRouteApi<
    Alert,
    CreateAlert,
    AlertsQueryParams
> {
    get path() {
        return 'alerts';
    }

    constructor(api: ApiService) {
        super(api);
    }

    transformBackendData(data): Alert {
        return {
            ...data,
            dateConditions: data.dateConditions?.map((d) => ({
                ...d,
                days: decodeDays(d.days),
            })),
            actions: data.actions?.map((a) => ({
                ...a,
                emailAddresses: a.emailAddresses?.split(','),
                phoneNumbers: a.phoneNumbers?.split(','),
            })),
        };
    }

    transformFrontendData(data): Alert {
        return {
            ...data,
            dateConditions: data.dateConditions?.map((d) => ({
                ...d,
                days: encodeDays(d.days),
            })),
            actions: data.actions?.map((a) => ({
                ...a,
                emailAddresses: a.emailAddresses?.join(','),
                phoneNumbers: a.phoneNumbers?.join(','),
            })),
        };
    }

    async copy(data: CopyAlert): Promise<CreateResult> {
        return (await this.api.client
            .post(this.basePath, data)
            .toPromise()) as CreateResult;
    }
}

function decodeDays(daysValue: string) {
    const binaryDaysArray = daysValue.padStart(7, '0').split('').reverse();
    const selectedDaysValues = [];
    binaryDaysArray.forEach((char, index) => {
        if (char !== '0') {
            selectedDaysValues.push(index);
        }
    });
    return selectedDaysValues;
}

function encodeDays(days: number[]) {
    const daysValueMap = [1, 10, 100, 1000, 10000, 100000, 1000000];
    return String(days.reduce((total, day) => total + daysValueMap[day], 0));
}

export class AlertsApi extends CachedApi<
    Alert,
    CreateAlert,
    AlertPreview,
    AlertsQueryParams
> {
    raw: RawAlertsApi;
    rawAccess: RawAccessAdapter<Alert, CreateAlert>;

    constructor(private api: ApiService) {
        super();
        this.raw = new RawAlertsApi(api);
        this.rawAccess = createRawAccess(this.raw, {});
    }

    async loadPreview(id: number): Promise<AlertPreview> {
        await ensureLoaded([
            this.api.organizations,
            this.api.devices,
            this.api.pins,
        ]);
        await waitForItem(this, id);
        const alert = this.get(id);
        const organization = this.api.organizations.get(alert.customerId);
        const isPinned = this.api.pins.has('alert', alert.id);
        const devices = alert.devices
            .map((id) => this.api.devices.get(id))
            .filter((d) => d != null);
        return {
            type: 'alert',
            id,
            title: alert.name,
            subtitle: organization?.name ?? '',
            devices,
            isPinned,
            alert,
        };
    }

    async copy(data: CopyAlert): Promise<CreateResult> {
        const copy = await this.raw.copy(data);
        await this.loadItem(copy.id);
        return copy;
    }
}
