import { Injectable } from '@angular/core';
import {
    MatSnackBar,
    MatSnackBarRef,
    TextOnlySnackBar,
} from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class SnackBarService {
    constructor(
        private snackBar: MatSnackBar,
        private translate: TranslateService
    ) {}

    public async open(term: string, interpolation?: object): Promise<MatSnackBarRef<TextOnlySnackBar>> {
        return this.snackBar.open(await this.translate.instant(term, interpolation));
    }
}
