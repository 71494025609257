import { EventEmitter } from '@angular/core';
import { Widget } from './widget';

export enum GaugeType {
    ORIGINAL,
    CUSTOM,
}

export interface WidgetDialogData {
    settings: any;
    widget: Widget;
    datasourceObj: any;
    datasource: any;
    busNumbers: Record<string | number, string | number>;
}

export interface IWidgetDialog {
    settingsChanged: EventEmitter<any>;
    init: EventEmitter<any>;
    setData: (settings: WidgetDialogData) => void;
    validForm?: EventEmitter<any>;
}

export interface IDashboardResponse {
    id: number;
    name: string;
    customerId: number;
    content: any;
}

export function isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
}

export function isFloat(n) {
    return Number(n) === n && n % 1 !== 0;
}

export const regexpDatasourceValue = /[^[\]"?]+(?="?])/gi;
export const regexpDatasourceValueGroup = /datasources(?<source>\[".*"\]*)/i;

export enum ChartTheme {
    DEFAULT = 'default',
    DARK = 'dark',
    LIGHT = 'light',
}
export interface IWidgetTopicParse {
    source: {
        full: string;
        name: string;
        permission: string;
        bus: string;
        can: string;
        variable: string;
    };
    code: string;
}

export interface IWidgetSubTopic {
    [key: string]: IWidgetTopicParse;
}
