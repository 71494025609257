import { cached } from 'src/app/shared/cache';
import { getTypeName } from 'src/app/shared/models/device';
import {
    CreateModel,
    Model,
    ModelType,
    ModelsQueryParams,
} from 'src/app/shared/models/model';
import { ApiService } from './api.service';
import {
    CachedApi,
    RawAccessAdapter,
    createRawAccess,
    ensureLoaded,
    waitForItem,
} from './cached-api';
import { TopLevelRouteApi } from './top-level-route-api';
import { ModelPreview } from 'src/app/shared/models/preview';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { urlBaseV2 } from '../constants';
import { inject } from '@angular/core';

class RawModelsApi extends TopLevelRouteApi<
    Model,
    CreateModel,
    ModelsQueryParams
> {
    get path() {
        return 'models';
    }

    constructor(api: ApiService) {
        super(api);
    }

    getTypes() {
        return this.retrieve<ModelType[]>('types');
    }
}

export class ModelsApi extends CachedApi<
    Model,
    CreateModel,
    ModelPreview,
    ModelsQueryParams
> {
    private raw: RawModelsApi;
    rawAccess: RawAccessAdapter<Model, CreateModel>;
    translate: TranslateService;

    constructor(private api: ApiService) {
        super();
        this.raw = new RawModelsApi(api);
        this.rawAccess = createRawAccess(this.raw, {});
        this.translate = inject(TranslateService);
    }

    @cached
    async getTypes(): Promise<ModelType[]> {
        return await this.raw.getTypes();
    }

    async loadPreview(id: number): Promise<ModelPreview> {
        await ensureLoaded([
            this.api.organizations,
            this.api.devices,
            this.api.pins,
        ]);
        await waitForItem(this, id);
        const model = this.get(id);
        const isPinned = this.api.pins.has('model', model.id);
        const organization = this.api.organizations.get(model.customerId);
        const devices = this.api.devices.current({ modelId: id });
        const imageUrl = model.productImage
            ? urlBaseV2 + '/models/' + id + '/thumbnail'
            : environment.apiUrl + '/images/Placeholder-model.png';
        return {
            type: 'model',
            id,
            title: model.name,
            subtitle: organization?.name ?? '',
            imageUrl,
            details: [
                { name: 'type', value: getTypeName(model.deviceType) },
                { name: 'device.plural', value: devices.length.toString() },
            ],
            isPinned,
            model,
        };
    }
}
